/**
* @Author: Matthias Gohla <Matze>
* @Date:   2016-09-22T16:09:56+02:00
* @Email:  matze_lebt@gmx.de
* @Last modified by:   mBook
* @Last modified time: 2016-10-06T17:35:54+02:00
*/


import React, {Component} from 'react';
import PropTypes from 'prop-types';

const BOX_TITLE = 'Tipp:';
/**
 * MHintboxCheckout - …
**/
class MHintboxCheckout extends Component {
  /**
     * TODO jsdoc
     *
     * @return {object}
     */
  render() {
    const {title, hint, actions, style} = this.props;
    return (
      <div className="m-hintbox m-hintbox-checkout" style={style}>
        <h4 className="m-hintbox-checkout__title">{title}</h4>
        <p className="m-hintbox-checkout__text">
          {hint}
        </p>
        <div className="m-hintbox-checkout__actions">
          {actions}
        </div>
      </div>
    );
  }
}
MHintboxCheckout.propTypes = {
  title: PropTypes.string.isRequired,
  hint: PropTypes.any.isRequired,
  /**
     * should be ALink Component
     * @type {ALink}
     */
  actions: PropTypes.any,
  /**
     * inline style object
     * @type {Object}
     */
  style: PropTypes.object,
};
MHintboxCheckout.defaultProps = {
  title: BOX_TITLE,
  style: {},
};
export default MHintboxCheckout;

