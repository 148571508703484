/**
 * Custom Router Class (JSX and ES6 compatible)
 * that enables a default listener on history
 * and has externalHistory object passed via Props
 * externalHistory requires API from https://github.com/ReactTraining/history
 * inspired from: https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/modules/HashRouter.js (under MIT)
 */
import React from 'react';
import PropTypes from 'prop-types';
import createHistory from 'history/createHashHistory';
import {Router} from 'react-router';

/**
 * The public API for a <Router> that uses window.location.hash.
 */
class CustomHashRouter extends React.Component {
    history = this.props.externalHistory || createHistory(this.props);

    componentWillMount() {
      if ( this.props.refreshListener != null ) {
        // add listener for future navigation operations
        this.history.listen(this.props.refreshListener);
        // execute first refresh (inital)
        this.props.refreshListener(this.history.location);
      }
    }

    render() {
      return <Router history={this.history} children={this.props.children}/>;
    }
};

CustomHashRouter.propTypes = {
  basename: PropTypes.string,
  getUserConfirmation: PropTypes.func,
  hashType: PropTypes.oneOf(['hashbang', 'noslash', 'slash']),
  children: PropTypes.node,
  externalHistory: PropTypes.object,
  refreshListener: PropTypes.func,
};

export default CustomHashRouter;
