/**
 * TODO jsdoc here
 */
import React, {Component} from 'react';
import omit from 'lodash/omit';

class AsyncLoader extends Component {
  /**
     * TODO jsdoc here
     *
     * @param {object} props
     * @return {undefined}
     */
  constructor(props) {
    super(props);
    this.state = {};
  }

  /**
     * TODO jsdoc here
     *
     * @return {undefined}
     */
  componentDidMount() {
    const that = this;
    this.props.asyncComponent.then(
        (module) => that.unmounted ||
                that.setState({renderFinal: module.default}),
    );
  }

  /**
     * TODO jsdoc here
     *
     * @return {undefined}
     */
  componentWillUnmount() {
    this.unmounted = true;
  }

  /**
     * render
     *
     * @return {object}
     */
  render() {
    return (this.state.renderFinal) ?
            React.createElement(this.state.renderFinal, {
              ...omit(this.props, 'asyncComponent'),
            }) : (<span className="a-spinner a-spinner-square"></span>);
  }
}

export default AsyncLoader;
