const MOVE_DATE_MAX_DAYS_IN_PAST = 14;

const RENTAL_DATE_MAX_DAYS_IN_FUTURE = 30;

const DEPOSIT_MAX = 9000;

const DEPOSIT_MIN = 400;

export default {
  MOVE_DATE_MAX_DAYS_IN_PAST,
  RENTAL_DATE_MAX_DAYS_IN_FUTURE,
  DEPOSIT_MAX,
  DEPOSIT_MIN,
};

