/**
* @Author: Michael Neumair <mBook>
* @Date:   2016-09-29T18:06:40+02:00
* @Email:  7q7w7e7r@gmail.com
* @Last modified by:   mBook
* @Last modified time: 2016-09-30T22:13:07+02:00
*/

export const MALE = 'm';
export const FEMALE = 'f';

export default {
  male: MALE,
  female: FEMALE,
};
