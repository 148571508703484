/**
* @Author: Michael Neumair <mBook>
* @Date:   2016-09-20T11:53:34+02:00
* @Email:  7q7w7e7r@gmail.com
* @Last modified by:   mBook
* @Last modified time: 2016-09-20T12:10:03+02:00
*/

import React, {Component} from 'react';
import PropTypes from 'prop-types';

/**
 * ATextInputDescription - …
**/
class ATextInputDescription extends Component {
  render() {
    const {
      children,
    } = this.props;
	    return (
	        <p className="a-text a-text-input-description">{children}</p>
	    );
  }
}
ATextInputDescription.propTypes = {
  children: PropTypes.any,
};
ATextInputDescription.defaultProps = {};
export default ATextInputDescription;

