/**
* @Author: Michael Neumair <mBook>
* @Date:   2016-10-04T12:15:02+02:00
* @Email:  7q7w7e7r@gmail.com
* @Last modified by:   mBook
* @Last modified time: 2016-10-06T19:11:19+02:00
*/

import {getModel} from 'react-redux-form/src';
import store from './../store';
import {FORM_MODEL} from './../reducers/reducers';

/* lodash modules */
import forOwn from 'lodash/forOwn';
import cloneDeep from 'lodash/cloneDeep';
import pick from 'lodash/pick';
import map from 'lodash/map';
import findKey from 'lodash/findKey';
import get from 'lodash/get';

// i18n on dates
import moment from 'moment';
import trim from 'validator/lib/trim';

moment.locale('de');

/**
 * get state from store that is verything in form namespace
 * will use a clone to be able to modify and do calculations on returned value
 *
 * @return {object}
 */
function getFormValues() {
  const values = getModel(store.getState(), FORM_MODEL);
  return cloneDeep(values);
}

/**
 * will flatten a plain old js object with "." as key separator
 *
 * @param {object} data
 * @param {object} hash
 * @param {string} tree
 * @return {object}
 */
function getFlatFormValues(data, hash, tree) {
  let topLevel = false;

  if (data == null) {
    topLevel = true;
    data = pick(
        getFormValues(),
        [
          'objective',
          'object',
          'landlord',
          'firstTenant',
          'secondTenant',
          'payment',
          'bankAccount',
          'security',
          'billing',
          'selling',
        ]);

    // processing second tenant
    if ( !data.objective.useSecondTenant ) {
      data = pick(
          getFormValues(),
          [
            'objective',
            'object',
            'landlord',
            'firstTenant',
            'payment',
            'bankAccount',
            'security',
            'billing',
            'selling',
          ],
      );
    }
  }

  // eslint-disable-next-line guard-for-in
  for (const k in data) {
    const pk = (tree === '') ? k : tree + '.' + k;
    const v = data[k];
    if ( typeof(v) == 'string' ) {
      hash[pk] = v;
    } else {
      if ( typeof(v) == 'object' ) {
        if (v == null) {
          hash[pk] = null;
        } else if (v.hasOwnProperty('value')) {
          hash[pk] = v.value;
        } else {
          const valid = v.toDateString && moment(v.toDateString()).isValid();
          if (valid) {
            hash[pk] = moment(v.toDateString()).format('L');
          } else {
            getFlatFormValues(v, hash, pk);
          }
        }
      } else {
        if (typeof(v) == 'number' || typeof(v) == 'boolean') {
          hash[pk] = v.toString();
        }
      }
    }
  };

  // only execute if all recursive are processed; we are on recursion level 0
  if ( topLevel ) {
    const filter = map(getFormValues()['activeMask'], (checkoutKey) => {
      return checkoutKey.replace(/^checkout\./, '');
    });

    for ( const fkcd in hash ) {
      if (
      // test key existance
        (filter.indexOf(fkcd) > -1) ||
                // test if it ends as id "xyz.id"
                fkcd.substr(fkcd.lastIndexOf('.') + 1) === 'id' ||
                // test filter for prefixed names "xyz.abc." matches "xyz.abc.o" and "xyz.abc.p"
                filter.indexOf(fkcd.substr(0, fkcd.lastIndexOf('.') + 1)) > -1
      ) {
        // keep value
      } else {
        delete hash[fkcd];
      }
    }
  }

  return hash;
}

/**
 * applies a mapping in reverse original execution order
 * to transform object into returned value
 *
 * @param {object} mapping
 * @param {object} object
 * @return {object}
 */
function applyMappingReverse(mapping, object) {
  const mapsReverse = {};
  forOwn(object, (v, k) => {
    const tk = findKey(mapping, (o) => {
      if (o == null) {
        return false;
      } else {
        return o == k || ( o._key && o._key == k);
      }
    });
    if (tk) {
      if (mapping[tk]['_key']) {
        if (mapping[tk]['_reverse']) {
          if ( typeof mapping[tk]['_reverse'] == 'function' ) {
            mapsReverse[`${FORM_MODEL}.${tk}`] = mapping[tk]['_reverse'](v, mapsReverse);
          } else {
            if (typeof(v) == 'boolean') {
              v = v.toString();
            }
            mapsReverse[`${FORM_MODEL}.${tk}`] = mapping[tk]['_reverse'][v];
          }
        } else {
          mapsReverse[`${FORM_MODEL}.${tk}`] = findKey(mapping[tk], (o) => {
            return o == v;
          });
        }
      } else {
        if (tk.match(/Date$/)
          || mapping[tk].match(/_date\]$/)
          || mapping[tk].match(/\[until_[^\]]+\]$/)
          || mapping[tk].match(/\[dateOfBirth\]$/)
        ) {
          if (moment(v).isValid()) {
            mapsReverse[`${FORM_MODEL}.${tk}`] = moment(v).toDate();
          } else {
            mapsReverse[`${FORM_MODEL}.${tk}`] = null;
          }
        } else {
          mapsReverse[`${FORM_MODEL}.${tk}`] = v;
        }
      }
    }
  });
  return mapsReverse;
}

function appTrimToAllStrings(obj) {
  // Iterate over the keys of the object
  Object.keys(obj).forEach((key) => {
    // Check if the value of the key is a string
    if (typeof obj[key] === 'string') {
      // Apply trim function to the value of the key
      obj[key] = trim(obj[key]);
    }
    // Check if the value of the key is an object
    else if (typeof obj[key] === 'object' && obj[key] !== null) {
      // Apply this function recursively to the nested object
      applyTrimToAllStrings(obj[key]);
    }
  });
}

/**
 * applies a mapping to transform object into returned value
 *
 * @param {object} mapping
 * @param {object} object
 * @return {object}
 */
function applyMapping(mapping, object) {
  const maps = {};
  forOwn(object, (v, k ) => {
    if (typeof v == 'boolean') {
      v = v.toString();
    }
    if ( !mapping[k] ) {
      return;
    }

    let mapKey = null;
    if ( typeof(mapping[k]) == 'object' ) {
      mapKey = mapping[k]['_key'];
      if ( mapping[k]['_forward'] ) {
        maps[mapKey] = mapping[k]['_forward'](v, maps);
      } else {
        maps[mapKey] = mapping[k][v];
      }
    } else {
      mapKey = mapping[k];
      if ( v != null && v.match(/^[0-9]+\.[0-9]+$/) ) {
        maps[mapKey] = v.replace(/\.001$/, '.00').replace('.', ',');
      } else {
        maps[mapKey] = v;
      }
    }

    // null pristine fields
    if ( get( store.getState(), 'checkout.forms.' + k + '.pristine') && maps[mapKey] === '' ) {
      maps[mapKey] = null;
    }

    // null undefined fields
    if ( typeof maps[mapKey] == 'undefined' ) {
      maps[mapKey] = null;
    }

    // remove all null'd fields
    if ( maps[mapKey] === null ) {
      delete maps[mapKey];
    }
  });

  appTrimToAllStrings(maps);

  return maps;
}

export {
  getFormValues,
  applyMappingReverse,
  applyMapping,
  getFlatFormValues,
};

