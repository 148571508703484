import getKfxMapping from './kfxMapping';
import getKfdeMapping from './kfdeMapping';
import getLegacyMapping from './legacyMapping';
import {isKfx, useNewApi} from './constants.common';
import store from '../checkout/store';


let kfdeMapping = null;
let kfxMapping = null;
let legacyMapping = null;

export default () => {
  if (isKfx() && useNewApi()) {
    if (kfxMapping === null) {
      kfxMapping = getKfxMapping(store);
    }
    return kfxMapping;
  }

  if (useNewApi()) {
    if (kfdeMapping === null) {
      kfdeMapping = getKfdeMapping(store);
    }
    return kfdeMapping;
  }

  if (legacyMapping === null) {
    legacyMapping = getLegacyMapping(store);
  }

  return legacyMapping;
}