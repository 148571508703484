import axios from 'axios';
import reduce from 'lodash/reduce';
import {
  getCommUrl,
} from 'utilities/corsTargets';


import {LOAD_UCE_URL, SAVE_UCE_URL} from '../../utilities/corsTargets';

const loadUceData = function(partnerCode = null, cid = null) {
  const url = getCommUrl(
      LOAD_UCE_URL(partnerCode),
      {
          ...(cid ? {cid: cid} : {}),
        	...(partnerCode ? {partner_code: partnerCode} : {}),
      }
  );

  return axios.get(url);
};

const saveUceValues = function(store) {
  const cid = store.cid || null;
  const url = getCommUrl(SAVE_UCE_URL, {
    ...(cid ? {cid: cid} : {}),
    ...(typeof window != 'undefined' && window.OA_PRODUCT_SELECTION) ?
            {product_selection: window.OA_PRODUCT_SELECTION} :
            {},
  });

  const data = {
    partner_code: store.getState().checkout.billing.partner_code,
    ...reduce(
        store.getState().checkout.uce,
        (reduced, value, key) => {
          reduced[`tenancy_retail[${key}]`] = value;
          return reduced;
        },
        {},
    ),

  };

  let putBody = '';

  for (const formName in data) {
    putBody += `${formName}=${encodeURIComponent(data[formName])}`;
    putBody += '&';
  }

  putBody = putBody.replace(/&$/, '');

  return new Promise((resolve, error) => {
    const oReq = new XMLHttpRequest();
    oReq.addEventListener('load', function() {
      resolve(this);
    });
    oReq.addEventListener('error', function() {
      error(this);
    });
    oReq.addEventListener('abort', function() {
      error(this);
    });
    oReq.open('PUT', url, true);
    oReq.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    oReq.send(putBody);
  }).then(({responseText}) => {
    return {
      data: JSON.parse(responseText),
    };
  });
};

export {
  loadUceData,
  saveUceValues,
};
