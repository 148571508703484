export const HAS_ACTIVE_MASK_FOR = 'HAS_ACTIVE_MASK_FOR';

/**
 * TODO jsdoc
 *
 * @param {(string|array)} mask
 * @param {boolean} merge
 * @return {object}
 */
export function activeMaskAction(mask, merge = false) {
  // wrap single value with array
  if ( typeof mask == 'string' ) {
    mask = [mask];
  }

  return {
    type: HAS_ACTIVE_MASK_FOR,
    activeMask: mask,
    activeMaskMerge: merge,
  };
}

