/**
* @Author: Michael Neumair <mBook>
* @Date:   2016-10-04T15:58:39+02:00
* @Email:  7q7w7e7r@gmail.com
* @Last modified by:   mBook
* @Last modified time: 2016-10-06T15:36:00+02:00
*/


import {
  CLOSE_HELP,
  SET_HELP_HTML,
  OPEN_HELP,
} from './../actions/checkoutHelpActions';

const initialState = {
  open: false,
  html: null,
  helpId: null, // used to identify same request and preserve existing html content
};

/**
 * logic handling anything from actions/checkoutHelpActions
 *
 * @param {object} previousState
 * @return {object}
 */
function checkoutHelp(previousState = initialState, {type, payload}) {
  switch (type) {
    case OPEN_HELP:
      const helpId = payload.helpId;
      return {
        ...previousState,
        open: true,
        helpId: helpId,
      };

    case CLOSE_HELP:
      return {
        ...previousState,
        open: false,
      };

    case SET_HELP_HTML:
      return {
        ...previousState,
        html: payload.html,
      };

    default:
      return previousState;
  }
}

export default checkoutHelp;

