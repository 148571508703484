/**
* @Author: Michael Neumair <mBook>
* @Date:   2016-09-29T18:06:40+02:00
* @Email:  7q7w7e7r@gmail.com
* @Last modified by:   mBook
* @Last modified time: 2016-10-06T18:29:27+02:00
*/

export const FIRST_TENANT = 'firstTenant';
export const SECOND_TENANT = 'secondTenant';

export default {
  firstTenant: FIRST_TENANT,
  secondTenant: SECOND_TENANT,
};
