import {
  getCommUrl,
} from 'utilities/corsTargets';
import {i18nInterface} from 'utilities/i18n_common';
import axios from 'axios';
import {LOAD_RATES} from '../../utilities/corsTargets';
import {useNewApi} from '../../config/constants.common';

const CACHE = {};
const getUrl = (sum, payment) => getCommUrl(
    LOAD_RATES,
    {
      sum: sum,
      payment: payment,
      [useNewApi() ? 'product' : 'product_selection']: window.OA_PRODUCT_SELECTION,
    },
);


/**
 * uses a component ref to setState() in it after asking ajax webservice for data
 *
 * @param {object} componentRef
 * @param {(number|string)} sum
 * @param {object} paymentConditions
 * @param {function} formatter
 * @return {undefined}
 */
function applyRatesToComponent(componentRef, sum,
    paymentConditions, formatter) {
  const requestBinding = getUrl(sum, paymentConditions);

  // use cache first
  if (typeof CACHE[requestBinding] != 'undefined') {
    const data = CACHE[requestBinding];
    componentRef.setState({
      ratePerPayment: formatter(
          parseFloat(
              data.rate_per_payment,
          ),
      ),
      ratePerYear: formatter(
          parseFloat(
              data.rate_per_year,
          ),
      ),
      feeText: i18nInterface('label.feeText1') +
        data['i18n_noun'] +
        i18nInterface('label.feeText2'),
    });
    return;
  }

  // do live request and append to cache
  const ajax = axios.get(requestBinding);
  ajax.then((response) => {
    const data = response.data;
    CACHE[requestBinding] = data;
    componentRef.setState({
      ratePerPayment: formatter(
          parseFloat(
              data.rate_per_payment,
          ),
      ),
      ratePerYear: formatter(
          parseFloat(
              data.rate_per_year,
          ),
      ),
      feeText: i18nInterface('label.feeText1') +
        data['i18n_noun'] +
        i18nInterface('label.feeText2'),
    });
  });
}

/**
 * uses a callback after asking ajax webservice on conditions
 *
 * @param {function} cb
 * @param {(number|string)} sum
 * @return {undefined}
 */
function getPaymentConditions(cb, sum) {
  const requestBinding = getUrl(sum, 'all');

  // use cache first
  if (typeof CACHE[requestBinding] != 'undefined') {
    cb(CACHE[requestBinding]);
    return;
  }

  // do live request and append to cache
  const ajax = axios.get(requestBinding);
  ajax.then((response) => {
    const data = response.data;
    CACHE[requestBinding] = data;
    cb(data);
  });
}

export default applyRatesToComponent;

export {getPaymentConditions};
