/**
* @Author: Michael Neumair <mBook>
* @Date:   2016-09-27T12:09:24+02:00
* @Email:  7q7w7e7r@gmail.com
* @Last modified by:   mBook
* @Last modified time: 2016-09-29T15:20:29+02:00
*/


import {createStore, applyMiddleware, compose, combineReducers} from 'redux';
import thunkMiddleware from 'redux-thunk';

// reducers
import reducers from './reducers/reducers';

const devtools = (
    typeof window === 'object'
    && typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined'
) ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f;

const store = createStore(
    combineReducers(reducers), // enableBatching(combineReducers(reducers)),
    {},
    compose(
        applyMiddleware(thunkMiddleware),
        devtools,
    )
);
export default store;

