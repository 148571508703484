import axios from 'axios';
import {getCommUrl, LOAD_UCE_URL, SAVE_UCE_URL} from './corsTargets';
import {actions} from 'react-redux-form/src';
import { FORM_MODEL } from '../checkout/reducers/reducers';

const getPartner = function(partner_code = null, cid = null) {
  const url = getCommUrl(LOAD_UCE_URL(partner_code), {cid: cid});
  return axios.get(url);
};

const saveSubPartner = function(store) {
  const product = (typeof window != 'undefined' && window.OA_PRODUCT_SELECTION)
    ? window.OA_PRODUCT_SELECTION : null;
  const data = {
    cid: store.cid || null,
    partnerCode: store.getState().checkout.billing.partner_code,
    subPartnerCode: store.getState().checkout.uce.subPartnerCode || null,
    product: product,
    step: 'uce',
  };

  return axios.post(getCommUrl(SAVE_UCE_URL), data);
};

const setSubPartner = function(store, uceId) {
  store.dispatch(actions.change(`${FORM_MODEL}.uce.subPartnerCode`, uceId));
  saveSubPartner(store);
};

export {
  getPartner,
  saveSubPartner,
  setSubPartner,
};
