/* Helper for hash object that has readable information on infolinks */

import cloneDeep from 'lodash/cloneDeep';

/* infoLinks setter/getter */
let infoLinksObject = {};

/**
 * plain setter for infoLinksObject
 *
 * @param {object} data
 */
export function setInfoLinks(data = {}) {
  infoLinksObject = data;
}

/**
 * plain getter for infoLinksObject (cloned before return)
 *
 * @return {object}
 */
export function getInfoLinks() {
  return cloneDeep(infoLinksObject);
}

