/**
* @Author: Michael Neumair <mBook>
* @Date:   2016-09-27T12:16:48+02:00
* @Email:  7q7w7e7r@gmail.com
* @Last modified by:   mBook
* @Last modified time: 2016-10-10T10:49:41+02:00
*/

import bankOwnerValues from './../constants/bankOwnerValues';
import targetValues from './../constants/targetValues';
import deliveryToValues from './../constants/deliveryToValues'; // eslint-disable-line no-unused-vars
import genderValues from './../constants/genderValues'; // eslint-disable-line no-unused-vars
import formOfAddressValues from './../constants/formOfAddressValues'; // eslint-disable-line no-unused-vars
import {i18nInterface} from '../../utilities/i18n_kf'; // eslint-disable-line no-unused-vars

const emptyString = '';

const billing = {
  /**
     * storing the partner code of tenancy_retail
     * @type {String}
     */
  partner_code: null,
  hide_partner_code: false,
};

const objective = {
  /**
     *
     * @type {Number}
     */
  deposit: null,
  /**
     * if target is targetValues.newContract,
     * we display fewer options for object,
     * if targtet is targetValues.retrieveDeposit,
     * all options ofobject are shown
     * default: targetValues.newContract
     * @type {String} - one of the targetValues constants
     */
  target: targetValues.newContract,
  /**
     * if true, we use the second tenant
     * @type {Boolean}
     */
  useSecondTenant: false,
  /**
     * where we deliver the kautionsbürgschaft
     * @type {String} - one of the deliveryToValues constants
     */
  deliverTo: null,
  useDigitalGuarantee: true,
  /**
     * the period of payments in a year
     * (defaults to single payment: 1 in one year)
    */
  paymentConditions: null,
  /**
   * Acceptance von:
   * Datenschutzbestimmungen
   */
  privacyPolicyAccepted: null,
  /**
   * Acceptance von:
   * Beratungsprotokoll,
   * Produktinformationsblatt
   * Versicherungsbedingungen
   * Widerrufsbelehrunt
   */
  conditionsAccepted: null,
};

const object = {
  /**
     * address of the object
     * default values should be empty-strings
     * @type {Object} - object with strings
     */
  address: {
    street: emptyString,
    number: emptyString,
    zip: emptyString,
    city: emptyString,
  },
  /**
     * @type {Date}
     */
  contractSignDate: null,
  /**
     * @type {Number}
     */
  coldRent: null,
  /**
     * @type {Date}
     */
  moveInDate: null,
  /**
     * @type {Boolean}
     */
  isContractLimited: null,
  /**
     * @type {Date}
     */
  contractLimitDate: null,
  /**
     * the following options are only used when
     * objective.target ==== targetValues.retrieveDeposit
     * @type {Boolean}
     */
  isContractTerminated: null,
  /**
     * @type {Boolean}
     */
  isRentPaidUnpunctual: null,
  /**
     * @type {Boolean}
     */
  isDepositPaidCompletely: null,
  /**
     * @type {Boolean}
     */
  isDepositConsumed: null,
  /**
     * @type {Boolean}
     */
  isObjectDamaged: null,
};

const firstTenant = {
  /**
     * @type {String} - one of genderValues
     */
  gender: null,
  /**
     * @type {String}
     */
  firstName: emptyString,
  /**
     * @type {String}
     */
  lastName: emptyString,
  /**
     * @type {Date}
     */
  birthdayDate: null,
  /**
   * @type {Object} - object with strings
   */
  nationality: {value: 'DE', label: i18nInterface('nationalities.DE')},
  /**
     * @type {String}
     */
  email: emptyString,
  /**
     * @type {String}
     */
  phone: emptyString,
  /**
     * @type {Object} - object with strings
     */
  address: {
    street: emptyString,
    number: emptyString,
    zip: emptyString,
    city: emptyString,
  },
  /**
     * default: false
     * @type {Boolean}
     */
  isCreditCheckApproved: null,
};

const secondTenant = {
  /**
     * @type {String} - one of genderValues
     */
  gender: null,
  /**
     * @type {String}
     */
  firstName: emptyString,
  /**
     * @type {String}
     */
  lastName: emptyString,
  /**
     * @type {Date}
     */
  birthdayDate: null,
  /**
   * @type {Object} - object with strings
   */
  nationality: {value: 'DE', label: i18nInterface('nationalities.DE')},
  /**
     * @type {String}
     */
  email: emptyString,
  /**
     * @type {String}
     */
  phone: emptyString,
  /**
     * @type {Object} - object with strings
     */
  address: {
    street: emptyString,
    number: emptyString,
    zip: emptyString,
    city: emptyString,
  },
  /**
     * default: false
     * @type {Boolean}
     */
  isCreditCheckApproved: null,
};

const landlord = {
  /**
     * if formOfAddress is formOfAddressValues.male or
     * formOfAddressValues.female, firstName and lastName is used
     * when formOfAddress is formOfAddressValues.company,
     * companyName and companyNameAddition is used
     * @type {String} - one of formOfAddressValues
     */
  formOfAddress: null,
  /**
     * @type {String}
     */
  firstName: emptyString, // only used when formOfAddress === formOfAddressValues.male or .female
  /**
     * @type {String}
     */
  lastName: emptyString, // only used when formOfAddress === formOfAddressValues.male or .female
  /**
     * @type {String}
     */
  companyName: emptyString, // only used when formOfAddress === formOfAddressValues.company
  /**
     * @type {String}
     */
  companyNameAddition: emptyString, // only used when formOfAddress === formOfAddressValues.company
  /**
     * @type {Object} - object with strings
     */
  address: {
    street: emptyString,
    number: emptyString,
    zip: emptyString,
    city: emptyString,
  },
};

const bankAccount = {
  /**
     * if true, iban is uses, when false, accountNumber and bankCode is used
     * @type {Boolean} - default: true
     */
  useIban: true,
  /**
     * default: bankOwnerValues.firstTenant
     * @type {String} - one of bankOwnerValues
     */
  accountOwner: bankOwnerValues.firstTenant,
  /**
     * @type {String}
     */
  iban: emptyString, // only used, when useIban === true
  /**
     * @type {String}
     */
  accountNumber: emptyString, // only used, when useIban === false
  /**
     * @type {String}
     */
  bankCode: emptyString, // only used, when useIban === false
  /**
     * @type {String}
     */
  bankName: emptyString,
};

const security = {
  /**
     * @type {String}
     */
  captchaResponseKey: emptyString,

  /**
     * @type {String}
     */
  pb: emptyString,

  /**
     * @type {String}
     */
  pbt: emptyString,

  /**
     * @type {String}
     */
  pbk: emptyString,
};

const formHandling = {
  /**
     * default: false
     * @type {Boolean}
     */
  forceToShowErrors: false,

  /**
     * default: null
     * @type {String}
     */
  contractState: null,

  /**
     * default: false
     * @type {Boolean}
     */
  interactiveLock: false,

};

const selling = {
  /**
     * storing selling product: mailForwarding
     * @type {Boolean}
     */
  mailForwarding: null,
  /**
     * storing selling product: keySafeing
     * @type {Boolean}
     */
  keySafeing: null,
};

/* if "Control" components are mounted keys if active inputs are applied */
const activeMask = [];

/* user concern extension storage */
const uce = {};

export default {
  billing,
  objective,
  object,
  firstTenant,
  secondTenant,
  landlord,
  bankAccount,
  security,
  formHandling,
  selling,
  activeMask,
  uce,
};

