/**
* @Author: Michael Neumair <mBook>
* @Date:   2016-09-29T18:06:40+02:00
* @Email:  7q7w7e7r@gmail.com
* @Last modified by:   mBook
* @Last modified time: 2016-10-06T18:02:11+02:00
*/

export const OBJECT_ADDRESS = 'objectAddresss';
export const LEGAL_ADDRESS = 'legalAddress';
export const LANDLORD_ADDRESS = 'landlordAddress';
export const DIGITAL = 'digital';
export const DIGITAL_MAX_DEPOSIT = 7000;

export default {
  objectAddresss: OBJECT_ADDRESS,
  legalAddress: LEGAL_ADDRESS,
  landlordAddress: LANDLORD_ADDRESS,
  digital: DIGITAL,
  digitalMaxDeposit: DIGITAL_MAX_DEPOSIT,
};
