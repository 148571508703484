/**
* @Author: Matthias Gohla <Matze>
* @Date:   2016-08-01T10:36:38+02:00
* @Email:  matze_lebt@gmx.de
* @Last modified by:   mBook
* @Last modified time: 2016-09-09T10:27:25+02:00
*/
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import getDisplayName from './getDisplayName';
import keycode from 'keycode';

/**
 * Compose buttons with standard button-props
 * @param  {Class} WrappedComponent    Child Component
 * @return {Class} ButtonProps  Parent component
 */
export default function buttonProps(WrappedComponent) {
  /**
     * ButtonProps - handles active and disabled props
     * of buttons, so onPress is only fired when the button
     * is not disabled. Passes a new prop visualState to the button
     * which are the css-classes for the current button-visual-state.
     * @param {Class} props
     */
  class ButtonProps extends Component {
    render() {
      const {
        disabled,
        active,
        onPress,
        ...other
      } = this.props;

      let visualState = '';
      if (disabled) {
        visualState = 'disabled';
      } else if (active) {
        visualState = 'active';
      }

      return (
        <WrappedComponent
          tabIndex={0}
          {...other}
          onKeyPress={this.handleOnKeyPress}
          visualState={visualState}
          disabled={disabled}
          active={active}
          onPress={(disabled) ? null : onPress}
        />
      );
    }

        // fire onPress when keyboard enters
        handleOnKeyPress = (event) => {
          const {
            onKeyPress,
            disabled,
            onPress,
          } = this.props;

          if (onKeyPress) onKeyPress(event);

          switch (keycode(event)) { // eslint-disable-line default-case
            case 'enter':
              if (!disabled && onPress) onPress(event);
              break;
          }
        }
  }
  ButtonProps.propTypes = {
    type: PropTypes.oneOf(['button', 'submit', 'reset']),
    active: PropTypes.bool,
    disabled: PropTypes.bool,
    onPress: PropTypes.func,
    onKeyPress: PropTypes.func,
  };
  ButtonProps.defaultProps = {
    type: 'button',
  };

  ButtonProps.displayName = `ButtonProps(${getDisplayName(WrappedComponent)})`;

  return ButtonProps;
}

