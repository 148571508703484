let pendingPromise = false;
let fallbackModule = null;

export function loadHtmlContent(url, fallback) {
  return new Promise((resolve) => {
    const onContentLoaded = function onContentLoaded() {
      if (this.status != 200) {
        resolve(fallback);
      } else {
        resolve(this.responseText);
      }
    };
    const onContentLoadingFailed = function onHelpLoadingFailed() {
      // show the user an error message
      resolve(fallback); // eslint-disable-line max-len
    };

    const oReq = new XMLHttpRequest();
    oReq.addEventListener('load', onContentLoaded);
    oReq.addEventListener('error', onContentLoadingFailed);
    oReq.addEventListener('abort', onContentLoadingFailed);
    oReq.open('GET', url);
    oReq.send();
  });
}

/**
 * returns a string based on lookup of id
 * @param {string} id
 * @return {*} string
 */
export function i18nInterface(id) {
  // remove everything behind last "#" for lookup
  // we have this to use same message on multiple controls
  id = id.replace(/#[^#]+$/, '');

  // test for external interface first
  if (
    typeof js_plusforta_com_i18n != 'undefined' &&
    js_plusforta_com_i18n.i18nInterface
  ) {
    return js_plusforta_com_i18n.i18nInterface(id);
  } else {
    if (pendingPromise) return pendingPromise;
    if (fallbackModule) return fallbackModule(id);
    pendingPromise = import(
        /* webpackChunkName: "i18n_fallback" */
        'utilities/i18n_kf'
    ).then((module) => {
      pendingPromise = false;
      fallbackModule = module.i18nInterface;
      return fallbackModule;
    });
    return pendingPromise;
  }
}
