/**
* @Author: Michael Neumair <mBook>
* @Date:   2016-08-12T15:58:52+02:00
* @Email:  7q7w7e7r@gmail.com
* @Last modified by:   mBook
* @Last modified time: 2016-08-12T15:59:59+02:00
*/

/**
 * helper which extracts the display name of a component
 * @param  {Class} Component
 * @return {String} displayName
 */
function getDisplayName(Component) {
  return Component.displayName || Component.name || 'Component';
}
export default getDisplayName;
