/**
* @Author: Michael Neumair <mBook>
* @Date:   2016-08-09T16:31:08+02:00
* @Email:  7q7w7e7r@gmail.com
* @Last modified by:   mBook
* @Last modified time: 2016-10-06T18:25:53+02:00
*/


import React, {Component} from 'react';
import PropTypes from 'prop-types';
/**
 * ALinkColor - a colored link
**/

class ALinkColor extends Component {
  render() {
    const {
      children,
      href,
      onPress,
      target,
      style,
      hasTooltip,
    } = this.props;

    const tooltipProps = hasTooltip ? {
      'data-rh': '',
      'data-rh-at': 'right',
    } : {};
    return (
      <a
        className="a-link a-link-color"
        href={href}
        onClick={onPress}
        target={target}
        style={style}
        {...tooltipProps}
      >{children}</a>
    );
  }
}

ALinkColor.propTypes = {
  href: PropTypes.string,
  children: PropTypes.any,
  target: PropTypes.string,
  onPress: PropTypes.func,
  hasTooltip: PropTypes.bool,
};
ALinkColor.defaultProps = {
  hasTooltip: false,
  children: 'ALinkColor',
};
export default ALinkColor;

