let captcha = {comppleted: false};

export const setCaptchaCompleted = ({completed, pb, pbt, pbk}) => {
  captcha = {completed, pb, pbt, pbk};
};

export const getCaptchaData = () => {
  return captcha;
};

export const isCaptchaCompleted = () => {
  if (captcha.hasOwnProperty('completed')) {
    return captcha.completed || false;
  }

  return false;
};
