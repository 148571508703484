let sellingOptions = [
/*
 * Set of possibilities see following comment
 */
  /*
    {
        offerId: "keySafeing",
        handleBoxClick: true,
        showAdvantages: false,
        changesLocked: false,
        showLegalText: false,
        defaultValue: null,
    },
    {
        offerId: "mailForwarding",
        handleBoxClick: true,
        showAdvantages: false,
        changesLocked: false,
        showLegalText: false,
        defaultValue: null,
    },
    {
     offerId: "immoscout",

        handleBoxClick: true,
        showAdvantages: false,
        changesLocked: false,
        showLegalText: true,
        defaultValue: null,
    },
    {
        offerId: "keySafeing",
        multiOption: {
            "no" : false,
            "yes" : true,
        },
        handleBoxClick: false,
        showAdvantages: false,
        changesLocked: false,
        showLegalText: false,
        defaultValue: null,
    },
    {
        offerId: "mailForwarding",
        multiOption: {
            "no" : false,
            "yes" : true,
        },
        handleBoxClick: false,
        showAdvantages: false,
        changesLocked: false,
        showLegalText: false,
        defaultValue: null,
    },
    {
    offerId: "immoscout",
         multiOption: {
            "no" : false,
            "yes" : true,
        },
        handleBoxClick: false,
        showAdvantages: false,
        changesLocked: false,
        showLegalText: true,
        defaultValue: null,
    },
    {
        offerId: "keySafeing",
        handleBoxClick: true,
        showAdvantages: true,
        changesLocked: false,
        showLegalText: false,
        defaultValue: null,
    },
    {
        offerId: "mailForwarding",
        handleBoxClick: true,
        showAdvantages: true,
        changesLocked: false,
        showLegalText: false,
        defaultValue: null,
    },
    {
     offerId: "immoscout",
        handleBoxClick: true,
        showAdvantages: true,
        changesLocked: false,
        showLegalText: true,
        defaultValue: null,
    },
    {
        offerId: "keySafeing",
        multiOption: {
            "no" : false,
            "yes" : true,
        },
        handleBoxClick: false,
        showAdvantages: true,
        changesLocked: false,
        showLegalText: false,
        defaultValue: null,
    },
    {
        offerId: "mailForwarding",
        multiOption: {
            "no" : false,
            "yes" : true,
        },
        handleBoxClick: false,
        showAdvantages: true,
        changesLocked: false,
        showLegalText: false,
        defaultValue: null,
    },
    {
        offerId: "immoscout",
        multiOption: {
            "yes" : true,
            "no" : false,
        },
        handleBoxClick: false,
        showAdvantages: true,
        changesLocked: false,
        showLegalText: true,
        defaultValue: null,
    },
*/
];

export const getSellingOptions = () => {
  return sellingOptions;
};

export const setSellingOptions = (options) => {
  sellingOptions = options;
};

