function initChat( api_connect_key = null, async = false ) {
  // abort directly if we have no DOM methods
  if ( typeof window == 'undefined' || typeof document == 'undefined' ) {
    return false;
  }

  // replaced jquery load handler method with setTimeout() implementation
  if ( async ) {
    const asyncAppendWrapper = () => {
      if ( window.SNAPENGAGE_API_KEY ) {
        appendScript();
      } else {
        setTimeout(asyncAppendWrapper, 650);
      }
    };
    setTimeout(asyncAppendWrapper, 350);
  } else {
    appendScript();
  }

  // ported from webseite-rails3 app/assets/javascript/plugins/kf.snapengage.js
  function appendScript() {
    // no default init!
    window.SnapEngageNoInit = true;
    const se = document.createElement('script');
    se.type = 'text/javascript';
    se.async = true;
    se.src = '//commondatastorage.googleapis.com/code.snapengage.com/js/' + (api_connect_key || window.SNAPENGAGE_API_KEY) + '.js';

    function finalizeInit() {
      if ( typeof SnapABug == 'undefined' || typeof SnapEngage == 'undefined' ) {
        setTimeout(finalizeInit, 650);
        return false;
      } else {
        SnapABug.allowOffline(false);
        SnapABug.allowScreenshot(false);
        SnapEngage.add(api_connect_key || window.SNAPENGAGE_API_KEY, 'de');
        SnapABug.setSecureConnexion();
        SnapABug.setDomain(window.SNAPENGAGE_HOST || document.location.host);
        SnapABug.showScreenshotOption(false);
        // disable default button on desktop
        SnapABug.hideButton();
        // hide tab on mobileUI (initially)
        SnapABug.getMobileUI() && (SnapABug.getMobileUI().buttonView.button.style.display = 'none');
        return true;
      }
    }

    setTimeout(finalizeInit, 950);

    const s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(se, s);
  }
}

export default initChat;

