import {i18nInterface} from '../utilities/i18n_common';
import nationalities from '../utilities/nationalities';

function booleanMapping(key) {
  return {
    '_key': key,
    'true': '1',
    'false': '0',
    '_reverse': {
      'false': false,
      'true': true,
      '0': false,
      '1': true,
    },
  };
}

export default (store) => {
  return {
    'objective.target': {
      '_key': 'tenancy_retail[apartment_attributes][is_existing]',
      'newContract': '0',
      'retrieveDeposit': '1',
      '_reverse': {
        'false': 'newContract',
        'true': 'retrieveDeposit',
        '0': 'newContract',
        '1': 'retrieveDeposit',
      },
    },
    'object.id': 'tenancy_retail[apartment_attributes][id]',
    'objective.deposit': 'tenancy_retail[apartment_attributes][sum]',
    'objective.privacyPolicyAccepted': 'tenancy_retail[privacy_policy_accepted]',
    'objective.conditionsAccepted': 'tenancy_retail[conditions_accepted]',
    'object.address.city': 'tenancy_retail[apartment_attributes][address_city]',
    'object.address.number': 'tenancy_retail[apartment_attributes][address_snumber]',
    'object.address.street': 'tenancy_retail[apartment_attributes][address_street]',
    'object.address.zip': 'tenancy_retail[apartment_attributes][address_zip]',
    'objective.deliverTo': {
      '_key': 'tenancy_retail[apartment_attributes][postal_dest]',
      'legalAddress': '0',
      'objectAddresss': '1',
      'landlordAddress': '2',
      '_reverse': {
        '0': 'legalAddress',
        '1': 'objectAddresss',
        '2': 'landlordAddress',
      },
    },
    'object.contractSignDate': 'tenancy_retail[apartment_attributes][rental_date]',
    'object.coldRent': 'tenancy_retail[apartment_attributes][sum_rent]',
    'object.moveInDate': 'tenancy_retail[apartment_attributes][move_date]',
    'object.isContractLimited': booleanMapping('tenancy_retail[apartment_attributes][is_temporary]'),
    'object.contractLimitDate': 'tenancy_retail[apartment_attributes][until_temporary]',
    'object.isContractTerminated': booleanMapping('tenancy_retail[is_apartment_cancelled]'),
    'object.isRentPaidUnpunctual': booleanMapping('tenancy_retail[is_unreliable_debitor]'),
    'object.isDepositPaidCompletely': booleanMapping('tenancy_retail[is_deposit_paid]'),
    'object.isDepositConsumed': booleanMapping('tenancy_retail[is_deposit_spent]'),
    'object.isObjectDamaged': booleanMapping('tenancy_retail[is_damaged]'),
    'landlord.id': 'tenancy_retail[lessor_attributes][id]',
    'landlord.formOfAddress': {
      '_key': 'tenancy_retail[lessor_attributes][gender]',
      'company': 'n',
      'm': 'm',
      'f': 'f',
      '_reverse': {
        'n': 'company',
        'm': 'm',
        'f': 'f',
      },
    },
    'landlord.firstName': 'tenancy_retail[lessor_attributes][first_name]',
    'landlord.lastName': 'tenancy_retail[lessor_attributes][name]',
    'landlord.companyName': 'tenancy_retail[lessor_attributes][company_name]',
    'landlord.companyNameAddition': 'tenancy_retail[lessor_attributes][company_name_appendix]',
    'landlord.address.street': 'tenancy_retail[lessor_attributes][address_street]',
    'landlord.address.number': 'tenancy_retail[lessor_attributes][address_snumber]',
    'landlord.address.zip': 'tenancy_retail[lessor_attributes][address_zip]',
    'landlord.address.city': 'tenancy_retail[lessor_attributes][address_city]',
    'firstTenant.id': 'tenancy_retail[lessee_attributes][0][id]',
    'firstTenant.gender': 'tenancy_retail[lessee_attributes][0][gender]',
    'firstTenant.firstName': 'tenancy_retail[lessee_attributes][0][first_name]',
    'firstTenant.lastName': 'tenancy_retail[lessee_attributes][0][name]',
    'firstTenant.birthdayDate': 'tenancy_retail[lessee_attributes][0][birth_date]',
    'firstTenant.nationality': {
      '_key': 'tenancy_retail[lessee_attributes][0][nationality]',
      '_forward': (value) => value,
      '_reverse': (value) => {
        const nationality = nationalities.find((el) => {
          return el.countryCode == value;
        });
        if (nationality) {
          return {value: nationality.countryCode, label: i18nInterface(`nationalities.${nationality.countryCode}`)};
        }

        return null;
      },
    },
    'firstTenant.email': 'tenancy_retail[lessee_attributes][0][email]',
    'firstTenant.phone': 'tenancy_retail[lessee_attributes][0][phone]',
    'firstTenant.address.street': 'tenancy_retail[lessee_attributes][0][address_street]',
    'firstTenant.address.number': 'tenancy_retail[lessee_attributes][0][address_snumber]',
    'firstTenant.address.zip': 'tenancy_retail[lessee_attributes][0][address_zip]',
    'firstTenant.address.city': 'tenancy_retail[lessee_attributes][0][address_city]',
    'firstTenant.isCreditCheckApproved': 'tenancy_retail[lessee_attributes][0][terms_accepted]',
    'secondTenant.id': 'tenancy_retail[lessee_attributes][1][id]',
    'secondTenant.gender': 'tenancy_retail[lessee_attributes][1][gender]',
    'secondTenant.firstName': 'tenancy_retail[lessee_attributes][1][first_name]',
    'secondTenant.lastName': 'tenancy_retail[lessee_attributes][1][name]',
    'secondTenant.birthdayDate': 'tenancy_retail[lessee_attributes][1][birth_date]',
    'secondTenant.nationality': {
      '_key': 'tenancy_retail[lessee_attributes][1][nationality]',
      '_forward': (value) => value,
      '_reverse': (value) => {
        const nationality = nationalities.find((el) => {
          return el.countryCode == value;
        });
        if (nationality) {
          return {value: nationality.countryCode, label: i18nInterface(`nationalities.${nationality.countryCode}`)};
        }

        return null;
      },
    },
    'secondTenant.email': 'tenancy_retail[lessee_attributes][1][email]',
    'secondTenant.phone': 'tenancy_retail[lessee_attributes][1][phone]',
    'secondTenant.address.street': 'tenancy_retail[lessee_attributes][1][address_street]',
    'secondTenant.address.number': 'tenancy_retail[lessee_attributes][1][address_snumber]',
    'secondTenant.address.zip': 'tenancy_retail[lessee_attributes][1][address_zip]',
    'secondTenant.address.city': 'tenancy_retail[lessee_attributes][1][address_city]',
    'secondTenant.isCreditCheckApproved': 'tenancy_retail[lessee_attributes][1][terms_accepted]',
    'bankAccount.id': 'tenancy_retail[banking_account_attributes][id]',
    'bankAccount.useIban': null,
    'bankAccount.accountOwner': {
      '_key': 'tenancy_retail[banking_account_attributes][holder_selection]',
      '_forward': function(fvalue, context) {
        if (typeof store.getState().checkout.firstTenant.id != 'undefined' &&
                    store.getState().checkout.bankAccount.accountOwner == 'firstTenant') {
          return 'lessee-' + store.getState().checkout.firstTenant.id;
        } else {
          if (typeof store.getState().checkout.secondTenant.id != 'undefined' &&
                        store.getState().checkout.bankAccount.accountOwner ==
                        'secondTenant') {
            return 'lessee-' + store.getState().checkout.secondTenant.id;
          }
        }
        return null;
      },
      '_reverse': function(rvalue, context) {
        if (rvalue == 'lessee-' + context['checkout.firstTenant.id']) {
          return 'firstTenant';
        } else {
          if (rvalue == 'lessee-' + context['checkout.secondTenant.id']) {
            return 'secondTenant';
          }
        }
        return null;
      },
    },
    'bankAccount.iban': 'tenancy_retail[banking_account_attributes][iban]',
    'bankAccount.accountNumber': 'tenancy_retail[banking_account_attributes][account_code]',
    'bankAccount.bankCode': 'tenancy_retail[banking_account_attributes][bank_code]',
    'bankAccount.bankName': 'tenancy_retail[banking_account_attributes][bank_name]',
    'billing.partner_code': 'tenancy_retail[partner_code]',
    'security.captchaResponseKey': 'captchaResponseKey',
    'security.pb': 'pb',
    'security.pbt': 'pbt',
    'security.pbk': 'pbk',
    'formHandling.contractState': 'tenancy_retail[state]',
    'object.hasPreinsurance': booleanMapping('tenancy_retail[has_preinsurance]'),
    'object.preinsuranceCompany': 'tenancy_retail[preinsurance_company]',
    'object.preinsuranceId': 'tenancy_retail[preinsurance_id]',
    'object.preinsuranceQuitBy': 'tenancy_retail[preinsurance_quit_by]',
    'object.hasPredamage': booleanMapping('tenancy_retail[has_predamage]'),
    'object.predamageDetail': 'tenancy_retail[predamage_detail]',
    'selling.keySafeing': booleanMapping('tenancy_retail[selling_key_safeing]'),
    'selling.keyFinder': booleanMapping('tenancy_retail[selling_key_finder]'),
    'selling.wechselpilot': booleanMapping('tenancy_retail[selling_wechselpilot]'),
    'selling.mailForwarding': booleanMapping('tenancy_retail[selling_mail_forwarding]'),
    'selling.immoscout': booleanMapping('tenancy_retail[selling_lead_transfer]'),
    'selling.preisboerse': booleanMapping('tenancy_retail[selling_preisboerse]'),
    'selling.preisboerse_time': 'tenancy_retail[selling_preisboerse_time]',
    'selling.selfStorage': booleanMapping('tenancy_retail[selling_self_storage]'),
    'selling.immoscout_area': 'tenancy_retail[selling_lead_transfer_area]',
    'objective.paymentConditions': 'tenancy_retail[payment]',
    'selling.movePackage': booleanMapping('tenancy_retail[selling_move_package]'),
    'selling.MieterEngel': booleanMapping('tenancy_retail[selling_mieterengel]'),
    'selling.MieterEngel_phone': booleanMapping('tenancy_retail[selling_mieterengel_phone]'),
  };
};
