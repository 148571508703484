/**
* @Author: Michael Neumair <mBook>
* @Date:   2016-10-04T12:15:02+02:00
* @Email:  7q7w7e7r@gmail.com
* @Last modified by:   mBook
* @Last modified time: 2016-10-06T19:18:36+02:00
*/

import {getField} from 'react-redux-form/src';
import warning from 'warning';
import store from './../store';
import {FORM_MODEL} from './../reducers/reducers';

/**
 * checks if a form or sub-element of form is considered valid
 * works with react-redux-form
 *
 * @return {bool}
 */
function isFormValid() {
  const field = getField(store.getState(), FORM_MODEL);
  return field.valid === true;
}
export default isFormValid;

