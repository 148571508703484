import React from 'react';
import axios from 'axios';
import ReactDOM from 'react-dom';
import AModal from '../checkout/components/AModal';
import {
  APPLICATION_CALLBACK_URL,
  getCommUrl,
  getRedirectUrl,
  LOAD_STATE_FROM_CID,
  RESERVATION_CALLBACK_URL,
} from './corsTargets';

const modalTimeout = 20000;
const modalTimeoutFinish = 90000;
const getModalTimeout = (isReservation) => isReservation() ? modalTimeout : modalTimeoutFinish;
const handleCheckoutAsync = (hash, isReservationActive) => {
  showModal(isReservationActive);
  getStateOfApplicationEverySecond(hash, isReservationActive);
  setTimeout(() => redirectTo('pending', isReservationActive, hash, null, null,
      digital), getModalTimeout(isReservationActive));
};

const showModal = (isReservationActive) => {
  ReactDOM.render(
      <AModal>
        <div className="a-spinner a-spinner-square"></div>
        {isReservationActive()
            ? <div className="handle-checkout-async__format">Ihre Reservierung wird bearbeitet</div>
            : <div className="handle-checkout-async__format">Ihr Vertrag wird bearbeitet<br/>(Dies kann bis zu 2 Minuten dauern)</div>}
      </AModal>, document.getElementById('modalAnchor'));
};

const redirectTo = (
    state,
    isReservationActive,
    hash,
    orderId = null,
    pramie = null,
    digital,
) => {
  const query = {
    cid: hash,
    pramie: pramie,
    vertragsnr: orderId,
    version: '2',
    digital: digital,
  };

  switch (state) {
    case 'accepted':
      query.is_accepted = true;
      break;
    case 'declined':
      query.is_accepted = false;
      break;
    case 'pending':
      query.is_accepted = false;
      query.is_pending = true;
      break;
  }

  const callbackBaseUrl = isReservationActive() ? RESERVATION_CALLBACK_URL : APPLICATION_CALLBACK_URL;
  const url = getRedirectUrl(callbackBaseUrl, query);
  window.location.replace(url);
};

const getStateOfApplicationEverySecond = (hash, isReservationActive) => {
  const interval = setInterval(function() {
    getStateOfApplication(hash, isReservationActive).
        then(({state, orderId, rate, digital}) => {
          if (state !== 'submitted' && state !== 'pending' && state !== 'created') {
            clearInterval(interval);
            redirectTo(state, isReservationActive, hash, orderId, rate, digital);
          }
        });
  }, 1000);
};

const getStateOfApplication = async (hash, isReservationActive) => {
  const url = getCommUrl(LOAD_STATE_FROM_CID(isReservationActive())) + `/${hash}`;
  const response = await axios.get(url);
  return {
    state: response.data.state,
    orderId: response.data.orderId,
    rate: response.data.rate,
    digital: response.data.digital,
  };
};

export {
  handleCheckoutAsync,
};
