import {i18nInterface} from '../utilities/i18n_common';
import nationalities from '../utilities/nationalities';

function booleanMapping(key) {
  return {
    '_key': key,
    'true': 'true',
    'false': 'false',
    '_reverse': {
      'false': false,
      'true': true,
      '0': false,
      '1': true,
    },
  };
}

export default (store) => {
  return {
    'objective.deposit': {
      '_key': 'rentalObject[rentalContract][deposit]',
      '_forward': (value) => value ? value.replace(',', '.') : null,
      '_reverse': (value) => value ? parseFloat(value) : null,
    },
    'object.address.city': 'rentalObject[address][city]',
    'object.address.number': 'rentalObject[address][streetNumber]',
    'object.address.street': 'rentalObject[address][street]',
    'object.address.zip': 'rentalObject[address][zip]',
    'objective.deliverTo': {
      '_key': 'postalDestination',
      'legalAddress': 'legal_address',
      'objectAddresss': 'object_address',
      'landlordAddress': 'landlord_address',
      '_reverse': {
        'legal_address': 'legalAddress',
        'object_address': 'objectAddresss',
        'landlord_address': 'landlordAddress',
      },
    },
    'object.contractSignDate': 'rentalObject[rentalContract][signedAt]',
    'object.coldRent': {
      '_key': 'rentalObject[rentalContract][rent]',
      '_forward': (value) => value.replace(',', '.'),
      '_reverse': (value) => value ? parseFloat(value) : null,
    },
    'object.moveInDate': 'rentalObject[rentalContract][movedInAt]',
    'object.isContractLimited': booleanMapping('rentalObject[rentalContract][isLimited]'),
    'object.contractLimitDate': 'rentalObject[rentalContract][limitedUntil]',
    'landlord.formOfAddress': {
      '_key': 'landlord[gender]',
      'company': 'company',
      'm': 'male',
      'f': 'female',
      '_reverse': {
        'company': 'company',
        'male': 'm',
        'female': 'f',
      },
    },
    'landlord.firstName': 'landlord[firstName]',
    'landlord.lastName': 'landlord[name]',
    'landlord.companyName': 'landlord[companyName]',
    'landlord.companyNameAddition': 'landlord[companyNameAppendix]',
    'landlord.address.street': 'landlord[address][street]',
    'landlord.address.number': 'landlord[address][streetNumber]',
    'landlord.address.zip': 'landlord[address][zip]',
    'landlord.address.city': 'landlord[address][city]',
    'firstTenant.gender': {
      '_key': 'firstTenant[gender]',
      'm': 'male',
      'f': 'female',
      '_reverse': {
        'male': 'm',
        'female': 'f',
      },
    },
    'firstTenant.firstName': 'firstTenant[firstName]',
    'firstTenant.lastName': 'firstTenant[name]',
    'firstTenant.birthdayDate': 'firstTenant[dateOfBirth]',
    'firstTenant.email': 'firstTenant[email]',
    'firstTenant.phone': 'firstTenant[phone]',
    'firstTenant.address.street': 'firstTenant[address][street]',
    'firstTenant.address.number': 'firstTenant[address][streetNumber]',
    'firstTenant.address.zip': 'firstTenant[address][zip]',
    'firstTenant.address.city': 'firstTenant[address][city]',
    'firstTenant.isCreditCheckApproved': booleanMapping('firstTenant[termsAccepted]'),
    'secondTenant.gender': {
      '_key': 'secondTenant[gender]',
      'm': 'male',
      'f': 'female',
      '_reverse': {
        'male': 'm',
        'female': 'f',
      },
    },
    'secondTenant.firstName': 'secondTenant[firstName]',
    'secondTenant.lastName': 'secondTenant[name]',
    'secondTenant.birthdayDate': 'secondTenant[dateOfBirth]',
    'secondTenant.email': 'secondTenant[email]',
    'secondTenant.phone': 'secondTenant[phone]',
    'secondTenant.address.street': 'secondTenant[address][street]',
    'secondTenant.address.number': 'secondTenant[address][streetNumber]',
    'secondTenant.address.zip': 'secondTenant[address][zip]',
    'secondTenant.address.city': 'secondTenant[address][city]',
    'secondTenant.isCreditCheckApproved': booleanMapping('secondTenant[termsAccepted]'),
    'bankAccount.useIban': null,
    'bankAccount.accountOwner': 'bankAccount[owner]',
    'bankAccount.iban': 'bankAccount[iban]',
    'bankAccount.bankName': 'bankAccount[bankName]',
    'billing.partner_code': 'partnerCode',
    'security.captchaResponseKey': 'captchaResponseKey',
    'security.pb': 'pb',
    'security.pbt': 'pbt',
    'security.pbk': 'pbk',
    'formHandling.contractState': 'state',
    'object.hasPreinsurance': booleanMapping('rentalObject[insurance][hasPreinsurance]',),
    'object.preinsuranceCompany': 'rentalObject[insurance][insurer]',
    'object.preinsuranceId': 'rentalObject[insurance][insuranceNumber]',
    'object.preinsuranceQuitBy': 'rentalObject[insurance][terminatedBy]',
    'object.hasPredamage': booleanMapping('rentalObject[insurance][hasPredamage]',),
    'object.predamageDetail': 'rentalObject[insurance][preDamage]',
    'selling.keySafeing': booleanMapping('selling[keySafeing]'),
    'selling.keyFinder': booleanMapping('selling[keyFinder]'),
    'selling.wechselpilot': booleanMapping('selling[wechselpilot]'),
    'selling.mailForwarding': booleanMapping('selling[mailForwarding]'),
    'selling.immoscout': booleanMapping('selling[leadTransfer]'),
    'selling.preisboerse': booleanMapping('selling[preisboerse]'),
    'selling.preisboerse_time': 'selling[preisboerseTime]',
    'selling.selfStorage': booleanMapping('selling[selfStorage]'),
    'selling.immoscout_area': 'selling[leadTransferArea]',
    'objective.paymentConditions': {
      '_key': 'bankAccount[paymentFrequency]',
      '1': 'annually',
      '2': 'semi-annually',
      '4': 'quarterly',
      '12': 'monthly',
      '_reverse': {
        'annually': '1',
        'semi-annually': '2',
        'quarterly': '4',
        'monthly': '12',
      },
    },
    'selling.movePackage': booleanMapping('tenancy_retail[selling_move_package]'),
  };
};
