/**
* @Author: Matthias Gohla <Matze>
* @Date:   2016-09-22T08:51:26+02:00
* @Email:  matze_lebt@gmx.de
* @Last modified by:   mBook
* @Last modified time: 2016-10-04T15:26:45+02:00
*/

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import AButton from './../../common/buttons/components/AButton';
import ALinkColor from './../../common/links/components/ALinkColor';
import ATextInputDescription from './../../common/inputs/components/ATextInputDescription';

const ICON = 'chevron-right';
export const ICON_POSITION_RIGHT = 'right';
export const ICON_POSITION_LEFT = 'left'; // eslint-disable-line no-unused-vars
export const BUTTON_STYLE_OUTLINE = 'outline';
export const BUTTON_STYLE_FILLED = 'filled';
export const BUTTON_STYLE_SPINNER = '';

/**
 * MNavigationBottomCheckout - …
**/
class MNavigationBottomCheckout extends Component {
  /**
     * TODO jsdoc
     *
     * @return {object}
     */
  render() {
    const {
      labelButtonForward,
      labelLinkPrevious,
      labelLinkSave,
      styleButtonForward,
      showLinkPrevious,
      onPressButtonForward,
      onPressLinkPrevious,
      onPressLinkSave,
      showLinkSave,
      subButtonText,
    } = this.props;
    return <div className="m-navigation-bottom m-navigation-bottom-checkout">
      <hr className="m-navigation-bottom-checkout__line" />
      { ( styleButtonForward != BUTTON_STYLE_SPINNER ) ?
                <div className="m-navigation-bottom-checkout__forward">
                  <AButton
                    onPress={onPressButtonForward}
                    title={labelButtonForward}
                    look={styleButtonForward}
                    icon={ICON}
                    size={'big'}
                    iconPosition={ICON_POSITION_RIGHT}
                    color={'orange'}
                  >
                    {labelButtonForward}
                  </AButton>
                </div> :
                <div className="m-navigation-bottom-checkout__forward">
                  <span className="a-spinner a-spinner-square"></span>
                </div>
      }
      {(labelLinkPrevious.length > 0) ?
                (<div className="m-navigation-bottom-checkout__previous">
                  <ALinkColor
                    onPress={onPressLinkPrevious}
                  >
                    {labelLinkPrevious}
                  </ALinkColor>
                </div>) : ''
      }
      { subButtonText != '' &&
                <ATextInputDescription>
                  {subButtonText}
                </ATextInputDescription>
      }
      {(showLinkSave && labelLinkPrevious.length > 0) ?
                (<div className="m-navigation-bottom-checkout__save">
                  <ALinkColor
                    onPress={onPressLinkSave}
                    hasTooltip={true}
                  >
                    {labelLinkSave}
                  </ALinkColor>
                </div>) : ''
      }
    </div>;
  }
}


MNavigationBottomCheckout.propTypes = {
  labelLinkPrevious: PropTypes.string,
  labelButtonForward: PropTypes.string,
  labelLinkSave: PropTypes.string,
  showLinkSave: PropTypes.bool,
  onPressLinkPrevious: PropTypes.func,
  onPressButtonForward: PropTypes.func,
  onPressLinkSave: PropTypes.func,
  /**
     * button styles for button forward
     */
  styleButtonForward: PropTypes.oneOf([BUTTON_STYLE_OUTLINE, BUTTON_STYLE_FILLED, BUTTON_STYLE_SPINNER]),
  subButtonText: PropTypes.string,
};
MNavigationBottomCheckout.defaultProps = {
  labelButtonPrevious: 'Zurück',
  labelButtonForward: 'Zum nächsten Schritt',
  styleButtonForward: 'outline',
  labelLinkPrevious: '',
  subButtonText: '',
};
export default MNavigationBottomCheckout;

