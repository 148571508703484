/* eslint-disable max-len */
import get from 'lodash/get';
import {getPaymentConditions} from 'checkout/utils/paymentConditions';

/**
 * @todo clean up long lines, replace inline strings with string concatenation
 */

/**
 * load html content form a url with GET
 * @param  {String} url
 *         source url to load from
 * @param  {string} fallback
 *         fallback when the laoding failed to show
 *         an error for the user
 * @return {Promise}
 */
function loadHtmlContent(url, fallback) {
  return new Promise((resolve) => {
    const onContentLoaded = function onContentLoaded() {
      if (this.status != 200) {
        resolve(fallback);
      } else {
        resolve(this.responseText);
      }
    };
    const onContentLoadingFailed = function onHelpLoadingFailed() {
      // show the user an error message
      resolve(fallback); // eslint-disable-line max-len
    };

    const oReq = new XMLHttpRequest();
    oReq.addEventListener('load', onContentLoaded);
    oReq.addEventListener('error', onContentLoadingFailed);
    oReq.addEventListener('abort', onContentLoadingFailed);
    oReq.open('GET', url);
    oReq.send();
  });
}

const FALLBACK_HELP = '<p class="a-text a-text-paragraphalt">' +
  'Fehler: Konnte Hilfe nicht laden. Bitte wenden Sie sich an den ' +
  'kostenlosen Support unter: <em>0800 - 01 22 333</em></p>';

const i18nData = {
  i18n_version: GIT_VERSION,
  navigate: {
    stepper: {
      start: 'Los geht\'s!',
      tenant: 'Los geht\'s!',
      error: 'Vorgang abgebrochen',
    },
    top: {
      'tenant': 'Mieter',
      'landlord': 'Vermieter',
      'object': 'Mietobjekt',
      'payment': 'Zahlung',
      'check': 'Bestätigen',
      'selling': 'Extras',
      '_append': 'FERTIG!',
    },
    forward_button_label: {
      start: 'Weiter zum Start',
      tenant: 'Weiter',
      landlord: 'Weiter zum Vermieter',
      object: 'Weiter zum Mietobjekt',
      payment: 'Weiter zur Zahlung',
      check: 'Weiter zur Übersicht',
      selling: 'Weiter zu Extras',
      finish_wizard: 'Kostenpflichtig abschliessen',
      finish_wizard_reservation: 'Reservierung absenden',
    },
    backward_button_label: {
      start: 'Zurück zum Start',
      tenant: 'Zurück zum Mieter',
      landlord: 'Zurück zum Vermieter',
      object: 'Zurück zum Mietobjekt',
      selling: 'Zurück zu Extras',
      payment: 'Zurück zur Zahlung',
    },
  },
  headlines: {
    error: 'Meldung',
    fieldset: {
      sum: 'Mietkautionsbürgschaft <span class="orange">beantragen</span> (Privatmieter)',
      target_of_contract: 'Verwendung',
      documentation_and_consent: 'Vertragsbedingungen',
      documentation_and_consent_selling: 'Vertragsbedingungen (Warenkorb)',
      personal_data: 'Persönliche Angaben',
      personal_contact: 'Kontaktdaten',
      personal_consent: 'Bonitätsprüfung',
      personal_current_address: 'Aktuelle Adresse',
      second_lessee: 'Zweiter Mieter',
      landlord_class: 'Anrede Ihres Vermieters',
      landlord_address: 'Adresse Ihres Vermieters',
      selling: 'Aktionen',
      keySafeing: 'gratis',
      keyFinder: 'gratis',
      mailForwarding: '',
      immoscout: 'gratis',
      selfStorage: 'gratis',
      uceClass: 'Kennung Untervermittler',
      movePackage: 'unverbindlich',
      preisboerse: 'gratis',
      wechselpilot: 'gratis Angebot erhalten',
      MieterEngel: 'Gratis',
    },
  },
  with_id: 'für Partner %s',
  confirm_documentation_and_consent: '* Bitte beachten Sie: Mit dem Klick auf "Kostenpflichtig abschließen" bestätigen Sie, dass Sie die Dokumente ' +
    '"Beratungsprotokoll", "Produktinformationsblatt", "Versicherungsbedingungen" und "Widerrufsbelehrung" zur Kenntnis genommen haben und die ' +
    'Kautionsbürgschaft zu den darin enthaltenen Bedingungen abschließen möchten.',
  confirm_privacy_policy: {
    first: 'Ich habe die ',
    privacy_policy: 'Datenschutzbestimmungen ',
    end: 'zur Kenntnis genommen.',
  },
  confirm_conditions: {
    first: 'Ich habe die Dokumente ',
    consultation_protocol: 'Beratungsprotokoll, ',
    product_information: 'Produktinformationsblatt, ',
    insurance_conditions: 'Versicherungsbedingungen ',
    middle: 'und ',
    cancellation_policy: 'Widerrufsbelehrung ',
    end: 'unten zur Kenntnis genommen und möchte die Kautionsbürgschaft zu den darin enthaltenen Bedingungen abschließen.',
  },
  postal_dest_questions: {
    object_address: [
      {
        text: 'Haben Sie schon dafür gesorgt, dass der Postbote Ihren Namen am neuen Briefkasten auch findet?',
      }, {
        text: 'Wählen Sie eine andere Option oder bringen Sie am besten noch heute den Namen am Briefkasten an, denn wir versenden die Bürgschaft ' +
          'meist innerhalb eines Werktages.',
      },
    ],
    legal_address: [
      {
        text: 'Haben Sie schon dafür gesorgt, dass der Postbote Ihren Namen an Ihrem alten Briefkasten noch findet?',
      }, {
        text: 'Wählen Sie eine andere Option oder bringen Sie am Besten noch heute den Namen am Briefkasten an, denn wir versenden die Bürgschaft ' +
          'meist innerhalb eines Werktages.',
      },
    ],
    landlord_address: [
      {
        text: 'Haben Sie Ihren Vermieter über die Zusendung der Bürgschaftsurkunde informiert?',
      }, {
        text: 'Informieren Sie Ihren Vermieter gleich, nachdem Sie den Antrag ausgefüllt haben. Wenn Sie möchten, können Sie unsere Vorlage verwenden, ' +
          'um Ihren Vermieter zu informieren.',
      },
    ],
  },
  your_order_title: 'Ihre Bestellung',
  your_order_value: 'Kautionsbürgschaft im Wert von',
  sum_final_page_title: 'Jahresbeitrag',
  error: {
    length: {
      max5: 'Dieses Feld darf nicht mehr als 5 Zeichen enthalten',
      max22: 'Dieses Feld darf nicht mehr als 22 Zeichen enthalten',
      max25: 'Dieses Feld darf nicht mehr als 25 Zeichen enthalten',
      max30: 'Dieses Feld darf nicht mehr als 30 Zeichen enthalten',
      max34: 'Dieses Feld darf nicht mehr als 34 Zeichen enthalten',
      max50: 'Dieses Feld darf nicht mehr als 50 Zeichen enthalten',
      max255: 'Dieses Feld darf nicht mehr als 255 Zeichen enthalten',
    },
    unknown: 'Ein unbekannter Fehler ist aufgetreten, bitte versuchen Sie es später erneut.',
    days_to_keep_data_exceeded: 'Aus Datenschutzgründen ist dieser Link nicht mehr gültig. Bei Fragen wenden Sie sich bitte an unseren Kundenservice unter 0800 01 22 333.',
    reservation_expired: 'Leider ist Ihre Reservierung abgelaufen. Sie müssen eine neuen Antrag starten.',
    already_submitted: 'Ihr Antrag wurde bereits verarbeitet, bitte prüfen Sie Ihren Email Posteingang.',
    global_appendix: 'Es sind noch nicht alle Felder richtig ausgefüllt. Bitte verbessern Sie die Eingabefelder, die jetzt rot markiert oder leer sind.',
    custom: {
      object: {
        coldRent: {
          isAllowedSum: 'Die angegebene Kautionssumme übersteigt die gesetzlich festgelegte Grenze ' +
            'von 3 Monatskaltmieten. Bitte passen Sie die Kautionssumme oder die Kaltmiete an. ' +
            'Beide Informationen finden Sie in Ihrem Mietvertrag.',
        },
        contractLimitDate: {
          isInFuture: 'muss in der Zukunft liegen',
        },
        contractSignDate: {
          isBeforeMoveInDate: 'muss vor dem Einzugsdatum liegen',
          isInNearFuture: 'darf maximal 30 Tage in der Zukunft liegen',
        },
        moveInDate: {
          isAfterContractSignDate: 'muss nach dem Mietvertragsdatum liegen',
          isInNearPast: '',
        },
      },
      sum: {
        tooHigh: 'ist zu hoch. Erteilung einer Bürgschaft in dieser Höhe nicht möglich. ' +
          'Maximalhöhe: 15000 €.',
        tooLow: 'ist zu niedrig. Erteilung einer Bürgschaft in dieser Höhe nicht möglich. ' +
          'Mindesthöhe: 400 €.',
      },
      tenant: {
        birthDate: {
          isLegalAge: 'Volljährigkeit zum Vertragsabschluss erforderlich',
          isTooOld: 'muss nach dem 01.01.1900 liegen',
        },
        nationality: 'Bitte wählen Sie eine Nationalität aus.',
      },
    },
    missing: {
      address: {
        street: 'Straße fehlt',
        number: 'Hausnummer fehlt',
        zip: 'PLZ fehlt',
        city: 'Stadt fehlt',
      },
      sum: 'Geben Sie die Höhe Ihrer Kaution an.',
      objective: {
        target: 'Wählen Sie eine Option.',
        paymentConditions: 'Wählen Sie eine Zahlweise.',
      },
      tenant: {
        gender: 'Wählen Sie eine Option.',
        firstName: 'Geben Sie Ihren Vornamen an',
        lastName: 'Geben Sie Ihren Nachnamen an',
        birthDate: 'Geben Sie ein gültiges Datum ein im Format TT.MM.JJJJ',
        isCreditCheckApproved: 'Um fortzufahren, benötigen wir Ihre Zustimmung',
        phone: 'Geben Sie Ihre Telefon- oder Handynummer an.',
        email: 'Geben Sie eine Email-Adresse ein.',
      },
      landlord: {
        gender: 'Wählen Sie eine Option.',
        companyName: 'Geben Sie den Firmennamen Ihres Vermieters an',
        firstName: 'Geben Sie den Vornamen Ihres Vermieters an',
        lastName: 'Geben Sie den Nachnamen Ihres Vermieters an',
      },
      object: {
        contractSignDate: 'Geben Sie ein gültiges Datum ein im Format ' +
          'TT.MM.JJJJ',
        moveInDate: 'Geben Sie ein gültiges Datum ein im Format TT.MM.JJJJ',
        isContractLimited: 'Wählen Sie eine Option.',
        coldRent: 'Geben Sie Ihre Kaltmiete an',
        isRentPaidUnpunctual: 'Wählen Sie eine Option.',
        isDepositConsumed: 'Wählen Sie eine Option.',
        isDepositPaidCompletely: 'Wählen Sie eine Option.',
        isContractTerminated: 'Wählen Sie eine Option.',
        isObjectDamaged: 'Wählen Sie eine Option.',
        contractWhen: 'Geben Sie ein gültiges Datum ein im Format TT.MM.JJJJ',
      },
      payment: {
        bankOwner: 'Wählen Sie eine Option.',
        iban: 'Geben Sie Ihre IBAN Verbindung an.',
        accountNumber: 'Geben Sie Ihre Kontonummer an.',
        bankCode: 'Geben Sie die Bankleitzahl Ihrer Bank an.',
        bankName: 'Geben Sie den Namen Ihres Kreditinstituts an',
        deliverTo: 'Wählen Sie eine Option.',
      },
      security: {
        captchaResponseKey: 'Lösen Sie die Sicherheitsaufgabe',
      },
      check: {
        acceptanceExplain: 'Zustimmung erforderlich',
      },
    },
    format: {
      address: {
        number: 'ungültig',
        zip: 'PLZ muss 5 stellig sein',
      },
      tenant: {
        email: 'Ihre Eingabe scheint kein gültiges Email-Format zu haben. Prüfen Sie Ihre Eingabe.',
        email_reentered: 'Die eingegebenen E-Mail-Adressen stimmen nicht überein, bitte geben Sie zwei mal die gleiche E-Mail-Adresse ein.',
        phone: 'ungültiges Format. Angabe im Format: 03012345 oder +493012345. Bitte ohne Leerzeichen.',
      },
      payment: {
        iban: 'ungültig',
      },
    },
  },
  taxIncluded: ' inkl. MwSt.',
  note: 'Hinweis:',
  hint: 'Tipp',
  yes: 'Ja',
  no: 'Nein',
  units: {
    euro: 'Euro',
  },
  query: 'Frage:',
  depositAmount: 'Kautionssumme',
  startNew: 'Neu beginnen',
  changePersonelData: 'Persönliche Daten ändern',
  continueWithApplication: 'Mit dem Antrag fortfahren',
  label: {
    edit: 'Editieren',
    address: {
      street: 'Straße',
      number: 'Hausnummer',
      zip: 'Postleitzahl',
      city: 'Stadt',
    },
    banner1: 'Bürgschaft statt ',
    banner2: ' Kaution,',
    feeText1: 'für ',
    feeText2: ' von:',
    annualPayment: 'jährlicher Beitrag',
    monthlyPayment: 'monatlicher Beitrag',
    secondTenant: 'Zweiter Mieter (optional)',
    partnerProgram: 'Partner-Id (Falls vorhanden)',
    partnerID: 'Vermittler-ID (falls vorhanden / optional)',
    help: 'Hilfe',
    helpIcon: 'question-sign',
    title: 'Anrede',
    titleValues: {
      male: 'Herr',
      female: 'Frau',
      business: 'Firma',
      company: 'Firma',
    },
    firstName: 'Vorname',
    lastName: 'Nachname',
    birthday: 'Geburtsdatum',
    email: 'Email-Adresse',
    email_reentered: 'Email-Adresse wiederholen',
    phone: 'Telefonnummer',
    company: 'Firma',
    company2: 'Namenszusatz (optional)',
    objective: {
      target: 'Wozu benötigen Sie die Kautionsbürgschaft?',
      targetValues: {
        new1: 'für einen ',
        new2: ' Mietvertrag',
        newVerb: 'neuen',
        old1: 'für einen ',
        old2: ' Mietvertrag',
        oldVerb: 'bestehenden',
        // blank line to sync with english version
      },
      placeholder: 'Kaution eintippen …',
      sum: 'Wie hoch ist Ihre Kaution?',
      sumName: 'Beitrag pro Jahr:',
      sum_description: 'Sie finden die Höhe Ihrer Kaution in Ihrem Mietvertrag.',
      sumInfo: 'Es entstehen KEINE versteckten Gebühren und Kosten.',
    },
    tenant: {
      address_description: 'Bitte tragen Sie hier Ihre aktuelle Adresse laut Personalausweis ein. ',
      address_extra_lessee_description: 'Bitte tragen Sie hier die aktuelle Meldeadresse des zweiten Mieters ein.',
      copy: 'Adresse von oben kopieren',
      agree: {
        first: 'Ich',
        optional: ', der erste Mieter,',
        optional2: ', der zweiter Mieter,',
        middle: ' stimme der ',
        check: 'Bonitätsprüfung',
        end: ' zu.',
      },
      removeSecond: 'Zweiten Mieter entfernen',
      addSecond: 'Zweiten Mieter hinzufügen (optional)',

    },
    nationality: 'Nationalität',
    nationality_placeholder: 'Auswählen...',
    landlord: {
      name: 'Name Ihres Vermieters',
      title: 'Tipp:',
      hint: 'Wenn Sie gerade nicht alle Unterlagen zur Hand haben, können ' +
        'Sie sich Ihre Bürgschaftszusage jetzt kostenlos reservieren und später ' +
        'mit dem Antrag fortfahren.',
      action: 'Reservieren und später fortfahren',
    },
    payment: {
      postal_dest_description: 'Bitte legen Sie fest, an welche Adresse Ihre Bürgschaftsurkunde ' +
        'versendet wird. Die Unterlagen werden, sofern der Antrag vor 15 Uhr bei uns eingeht, ' +
        'noch am gleichen Tag an die Deutsche Post übergeben. Anträge nach 15 Uhr werden ' +
        'spätestens am nächsten Werktag an die Deutsche Post übergeben. Ein Versand ins Ausland ' +
        'ist nicht möglich.',
      paymentConditions: 'Sie können zwischen monatlicher und jährlicher Zahlweise wählen. ' +
        'Im Falle einer Kündigung erhalten Sie zu viel gezahlte Beiträge ' +
        'selbstverständlich zurück.',
      paymentType: 'Zahlweise',
      means: 'Das entspricht ',
      accountNumber: 'Kontonummer',
      blz: 'Bankleitzahl',
      info: 'Bankverbindung',
      bank: 'Kreditinstitut',
      switch: 'Zwischen IBAN und Kontonummer/BLZ Eingabe umschalten',
      useIBAN: 'IBAN verwenden',
      useBLZ: 'Kontonummer/BLZ verwenden',
      deliveryType: 'Versand der Bürgschaft',
      deliveryTypeDescription: 'Bitte legen Sie fest, wie Sie Ihre Bürgschaftsurkunde erhalten möchten. Um die Umwelt zu schonen und die Urkunde direkt per E-Mail zu erhalten, empfehlen wir die digitale Bürgschaft.',
      digital: 'Digital (Versand sofort per E-Mail)',
      digital_disabled: 'Die Option der digitalen Bürgschaft ist leider für Kautionssummen ab 7.000 € nicht möglich.',
      printed: 'Gedruckte Bürgschaftsurkunde (Versand in wenigen Tagen per Post)',
      delivery: 'Lieferadresse',
      deliverTo: 'Wohin sollen wir die Bürgschaftsurkunde senden?',
      newAddress: 'An die neue Adresse',
      yesName: 'Ja, am Briefkasten steht mein Name',
      oldAddress: 'An die alte Adresse',
      myAddress: 'An mich',
      yesNameStill: 'Ja, am Briefkasten steht noch mein Name',
      landlord: 'An den Vermieter',
      yesIsWaiting: 'Ja, mein Vermieter wartet schon',
      captcha: 'Sicherheitsprüfung',
      yesEnsure: 'Okay, ich kümmere mich darum',
      greatExample: 'Super, wo finde ich die Vorlage?',
      okIWill: 'Okay, ich mache das.',
      queryNotAccepted: 'Versandhinweise müssen akzeptiert werden.',
    },
    object: {
      legend: 'Adresse des Mietobjekts',
      contract: {
        legend: 'Mietvertrag',
        signDate: 'Mietbeginn',
        coldRent: 'Kaltmiete pro Monat',
        moveInDate: 'Einzug am',
        isLimited: 'Ist der Mietvertrag befristet?',
        yesLimited: 'Ja, der Mietvertrag ist befristet',
        limitedWhen: 'Bis zu welchem Datum ist Ihr Mietvertrag befristet?',
      },
      risks: {
        legend: 'Mietverhältnis',
        isTerminated: 'Ja, es ist bereits gekündigt',
        wasLate: 'Ja, die Miete wurde mehr als einmal unpünktlich gezahlt',
        paidInFull: 'Ja, die Kaution ist vollständig überwiesen',
        isConsumed: 'Ja, die Kaution wurde teilweise oder ganz verbraucht',
        isDamaged: 'Ja, es liegen Schäden vor',
      },
      isContractTerminated: 'Ist das Mietverhältnis, für das gebürgt werden soll, bereits gekündigt?',
      isRentPaidUnpunctual: 'Haben Sie Ihre Miete mehr als einmal unpünktlich gezahlt?',
      isDepositPaidCompletely: 'Liegt die im Mietvertrag vereinbarte Kaution Ihrem Vermieter bereits vollständig vor?',
      isDepositConsumed: 'Hat der Vermieter während des Mietverhältnisses bereits aufgrund von durch Sie verursachten Schäden auf die Kaution zugegriffen?',
      isObjectDamaged: 'Liegen aktuell durch Sie verursachte Schäden (auch Unfälle) am Mietobjekt vor?',
    },
    checkout: {
      sum: 'Mietkaution (Privatmieter)',
      object: 'Mietobjekt',
      documents: 'Dokumente',
      address: 'Adresse',
      coldRent: 'Monatskaltmiete',
      limited: 'Mietvertag befristet',
      limitedDate: 'Mietvertag befristet bis',
      tenant: {
        one: 'Mieter',
        two: 'Zweiter Mieter',
      },
      nationality: 'Nationalität',
      title: 'Anrede',
      name: 'Name',
      birthday: 'Geburtsdatum',
      email: 'Email',
      phone: 'Telefonnummer',
      landlord: {
        title: 'Vermieter',
        landlord_class: 'Anrede Ihres Vermieters',
        name: 'Name Ihres Vermieters',
      },
      accountNumber: 'Kontonummer',
      bankCode: 'Bankkonto',
      paymentDetails: 'Zahlungsangaben',
      bankName: 'Kreditinstitut',
      deliverTo: 'Lieferadresse',
      deliverToDigital: 'Versand der Bürgschaft',
      to: 'Ziel',
      toDigital: 'Digital per E-Mail an',
      isObjectTerminated: 'Mietvertrag für das Mietobjekt bereits gekündigt',
      isRentPaidUnpunctual: 'Miete mehr als einmal unpünktlich bezahlt',
      isDepositPaidCompletely: 'Kaution vollständig an Vermieter überwiesen',
      isDepositConsumed: 'Kaution teilweise oder ganz verbraucht',
      isObjectDamaged: 'Liegen Schäden vor am Mietobjekt',
    },
  },
  saveProgress: {
    placeholder: 'Email Adresse eingeben',
    solution: 'Wir sichern Ihre Daten und senden Ihnen per E-Mail einen Link. Damit können Sie den Antrag bequem zu einem späteren Zeitpunkt fortsetzen. Nach 30 Tagen werden Ihre Daten automatisch gelöscht.',
    linkLabel: 'Später fortfahren',
    buttonLabel: 'Jetzt Eingaben speichern',
  },
  nationalities: {
    AF: "Afghanistan",
    AL: "Albanien",
    AQ: "Antarktis",
    DZ: "Algerien",
    AS: "Amerikanischen Samoa-Inseln",
    AD: "Andorra",
    AO: "Angola",
    AG: "Antigua und Barbuda",
    AZ: "Aserbaidschan",
    AR: "Argentinien",
    AU: "Australien",
    AT: "Österreich",
    BS: "Bahamas",
    BH: "Bahrein",
    BD: "Bangladesch",
    AM: "Armenien",
    BB: "Barbados",
    BE: "Belgien",
    BM: "Bermuda",
    BT: "Bhutan",
    BO: "Bolivien",
    BA: "Bosnien und Herzegowina",
    BW: "Botswana",
    BV: "Bouvet-Insel",
    BR: "Brasilien",
    BZ: "Belize",
    IO: "Britisches Territorium des Indischen Ozeans",
    SB: "Salomonen",
    VG: "Britische Jungferninseln",
    BN: "Brunei Darussalam",
    BG: "Bulgarien",
    MM: "Myanmar",
    BI: "Burundi",
    BY: "Weißrussland",
    KH: "Kambodscha",
    CM: "Kamerun",
    CA: "Kanada",
    CV: "Kap Verde",
    KY: "Cayman Inseln",
    CF: "Zentralafrikanische Republik",
    LK: "Sri Lanka",
    TD: "Tschad",
    CL: "Chile",
    CN: "China",
    TW: "Taiwan",
    CX: "Weihnachtsinsel",
    CC: "Cocos (Keeling) Islands",
    CO: "Kolumbien",
    KM: "Komoren",
    YT: "Mayotte",
    CG: "Kongo",
    CD: "Demokratische Republik Kongo",
    CK: "Cookinseln",
    CR: "Costa Rica",
    HR: "Kroatien",
    CU: "Kuba",
    CY: "Zypern",
    CZ: "Tschechische Republik",
    BJ: "Benin",
    DK: "Dänemark",
    DM: "Dominica",
    DO: "Dominikanische Republik",
    EC: "Ecuador",
    SV: "El Salvador",
    GQ: "Äquatorialguinea",
    ET: "Äthiopien",
    ER: "Eritrea",
    EE: "Estland",
    FO: "Färöer Inseln",
    FK: "Falklandinseln (Malvinas)",
    GS: "Süd-Georgien und die südlichen Sandwich-Inseln",
    FJ: "Fidschi",
    FI: "Finnland",
    FR: "Frankreich",
    GF: "Französisch-Guayana",
    PF: "Französisch Polynesien",
    TF: "Südfranzösische Territorien",
    DJ: "Dschibuti",
    GA: "Gabun",
    GE: "Georgien",
    GM: "Gambia",
    PS: "Staat Palästina",
    DE: "Deutschland",
    GH: "Ghana",
    GI: "Gibraltar",
    KI: "Kiribati",
    GR: "Griechenland",
    GL: "Grönland",
    GD: "Grenada",
    GP: "Guadeloupe",
    GU: "Guam",
    GT: "Guatemala",
    GN: "Guinea",
    GY: "Guyana",
    HT: "Haiti",
    HM: "Heard und McDonald-Inseln",
    VA: "Vatikanstadt",
    HN: "Honduras",
    HK: "Hongkong",
    HU: "Ungarn",
    IS: "Island",
    IN: "Indien",
    ID: "Indonesien",
    IR: "Iran",
    IQ: "Irak",
    IE: "Irland",
    IL: "Israel",
    IT: "Italien",
    CI: "Cote d'Ivoire",
    JM: "Jamaika",
    JP: "Japan",
    KZ: "Kasachstan",
    JO: "Jordanien",
    KE: "Kenia",
    KP: "Nordkorea",
    KR: "Südkorea",
    KW: "Kuwait",
    KG: "Kirgistan",
    LA: "Laos",
    LB: "Libanon",
    LS: "Lesotho",
    LV: "Lettland",
    LR: "Liberia",
    LY: "Libyen",
    LI: "Liechtenstein",
    LT: "Litauen",
    LU: "Luxemburg",
    MO: "Macao",
    MG: "Madagaskar",
    MW: "Malawi",
    MY: "Malaysia",
    MV: "Malediven",
    ML: "Mali",
    MT: "Malta",
    MQ: "Martinique",
    MR: "Mauretanien",
    MU: "Mauritius",
    MX: "Mexiko",
    MC: "Monaco",
    MN: "Mongolei",
    MD: "Moldawien",
    ME: "Montenegro",
    MS: "Montserrat",
    MA: "Marokko",
    MZ: "Mosambik",
    OM: "Oman",
    NA: "Namibia",
    NR: "Nauru",
    NP: "Nepal",
    NL: "Niederlande",
    CW: "Curacao",
    AW: "Aruba",
    SX: "Sint Maarten (niederländischen Teil)",
    BQ: "Bonaire",
    NC: "Neu-Kaledonien",
    VU: "Vanuatu",
    NZ: "Neuseeland",
    NI: "Nicaragua",
    NE: "Niger",
    NG: "Nigeria",
    NU: "Niue",
    NF: "Norfolkinsel",
    NO: "Norwegen",
    MP: "Nördliche Marianen",
    UM: "United States Minor Outlying Islands",
    FM: "Föderierte Staaten von Mikronesien",
    MH: "Marshallinseln",
    PW: "Palau",
    PK: "Pakistan",
    PA: "Panama",
    PG: "Papua Neu-Guinea",
    PY: "Paraguay",
    PE: "Peru",
    PH: "Philippinen",
    PN: "Pitcairn",
    PL: "Polen",
    PT: "Portugal",
    GW: "Guinea-Bissau",
    TL: "Timor-Leste",
    PR: "Puerto Rico",
    QA: "Katar",
    RE: "La Réunion",
    RO: "Rumänien",
    RU: "Russische Föderation",
    RW: "Ruanda",
    BL: "Collectivité de Saint-Barthélemy",
    SH: "St. Helena",
    KN: "St. Kitts und Nevis",
    AI: "Anguilla",
    LC: "St. Lucia",
    MF: "Saint Martin (Französisch Teil)",
    PM: "Saint-Pierre und Miquelon",
    VC: "St. Vincent und die Grenadinen",
    SM: "San Marino",
    ST: "Sao Tome und Principe",
    SA: "Saudi Arabien",
    SN: "Senegal",
    RS: "Serbien",
    SC: "Seychellen",
    SL: "Sierra Leone",
    SG: "Singapur",
    SK: "Slowakei",
    VN: "Vietnam",
    SI: "Slowenien",
    SO: "Somalia",
    ZA: "Südafrika",
    ZW: "Zimbabwe",
    ES: "Spanien",
    SS: "Südsudan",
    SD: "Sudan",
    EH: "Westsahara",
    SR: "Suriname",
    SJ: "Svalbard",
    SZ: "Swasiland",
    SE: "Schweden",
    CH: "Schweiz",
    SY: "Syrische Arabische Republik",
    TJ: "Tadschikistan",
    TH: "Thailand",
    TG: "Republik Togo",
    TK: "Tokelau",
    TO: "Tonga",
    TT: "Trinidad und Tobago",
    AE: "Vereinigte Arabische Emirate",
    TN: "Tunesien",
    TR: "Türkei",
    TM: "Turkmenistan",
    TC: "Turks- und Caicosinseln",
    TV: "Tuvalu",
    UG: "Uganda",
    UA: "Ukraine",
    MK: "Mazedonien, die ehemalige jugoslawische Republik",
    EG: "Ägypten",
    GB: "Großbritannien",
    GG: "Guernsey",
    JE: "Jersey",
    IM: "Isle of Man",
    TZ: "Vereinigte Republik Tansania",
    US: "Vereinigte Staaten",
    VI: "US Jungferninseln",
    BF: "Burkina Faso",
    UY: "Uruguay",
    UZ: "Usbekistan",
    VE: "Venezuela",
    WF: "Wallis und Futuna",
    WS: "Samoa",
    YE: "Jemen",
    ZM: "Sambia",
    XK: "Kosovo"
  },
  copy_first_lessee_data: 'Übertragen Sie die Adressdaten vom ersten Mieter',
  privacy_policy: '<h3 class="a-headline a-headline-section-black">Hinweise zum Datenschutz</h3>' +
    '<p class="a-text a-text-paragraphalt">Grundsätze</p><p class="a-text a-text-paragraphalt"> ' +
    'Die plusForta GmbH nimmt den Schutz personenbezogener Daten sehr ernst. Wir haben daher technische ' +
    'und organisatorische Maßnahmen getroffen, die sicherstellen, dass die Vorschriften über den ' +
    'Datenschutz und die Datensicherheit für unsere Vertragspartner sowie für die Verbraucher ' +
    'eingehalten werden.</p>' +
    '<p class="a-text a-text-paragraphalt">Personenbezogene Daten und Weitergabe an Dritte</p><p class="a-text a-text-paragraphalt"> ' +
    'Sofern innerhalb des Internetangebots die Möglichkeit zur Eingabe persönlicher oder geschäftlicher Daten ' +
    '(z. B. E-Mail-Adressen, Namen, Anschriften) besteht, so erfolgt die Preisgabe dieser Daten ' +
    'seitens des Nutzers auf ausdrücklich freiwilliger Basis. Diese Informationen werden von uns ' +
    'benötigt, um Ihnen die erbetenen Serviceleistungen zur Verfügung zustellen oder Ihnen das ' +
    'angeforderte Informationsmaterial zuzusenden. Ohne diese Angaben können wir unsere ' +
    'Serviceleistungen und Ihrem Antrag auf Vermittlung einer Kautionsbürgschaft nicht entsprechen. ' +
    'Personenbezogene Daten werden von uns erhoben, verarbeitet und genutzt, soweit dies zur ' +
    'Durchführung der angebotenen Serviceleistungen erforderlich ist, aber auch um mit Ihnen den ' +
    'gewünschten Kontakt aufzunehmen oder Ihnen angeforderte Serviceleistungen oder Informationen zur ' +
    'Verfügung zu stellen. Ihre personenbezogenen Daten werden auf geschützten Servern gespeichert und ' +
    'sind nur besonders befugten Personen zugänglich, die mit der Bearbeitung Ihrer Daten beauftragt ' +
    'sind.</p>' +
    '<p class="a-text a-text-paragraphalt">Cookies</p><p class="a-text a-text-paragraphalt"> ' +
    'Die Internetseiten verwenden an mehreren Stellen so genannte Cookies. Sie dienen dazu, unser ' +
    'Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind kleine Textdateien, die ' +
    'auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert. Die meisten der von uns ' +
    'verwendeten Cookies sind so genannte „Session-Cookies“. Sie werden nach Ende Ihres Besuchs ' +
    'automatisch gelöscht. Cookies richten auf Ihrem Rechner keinen Schaden an und enthalten keine ' +
    'Viren.</p>' +
    '<p class="a-text a-text-paragraphalt">Datenschutzbeauftragte/r</p><p class="a-text a-text-paragraphalt">' +
    'Bei Fragen zur Erhebung, Verarbeitung oder Nutzung Ihrer personenbezogenen Daten, bei Auskünften, ' +
    'Berichtigung, Sperrung oder Löschung von Daten wenden Sie sich bitte an die Datenschutzbeauftragte ' +
    'unseres Unternehmens: ' +
    '<br/> ' +
    'legal.solutions GmbH<br/> ' +
    'Oranienburger Str. 17<br/>' +
    '10178 Berlin <br />' +
    'Tel.: +49 (0) 30 467 24 06 18<br/> ' +
    'E-Mail: datenschutz@pagestreet.de</p>',
  bonity_policy: '<h3 class="a-headline a-headline-section-black">Was ist eine Bonitätsprüfung?</h3>' +
    '<p class="a-text a-text-paragraphalt">' +
    'Eine Bonitätsprüfung ist ein <strong>automatischer Check der' +
    ' Zahlungsfähigkeit.</strong> Sie geben Ihrem Vertragspartner eine Prognose' +
    ' zu Ihrer Kreditwürdigkeit – also <strong>eine Art Absicherung.' +
    '</strong> Die Auswertung erhält Ihr Vertragspartner in Form von' +
    ' Scoring-Punkten. Er erhält keine Informationen zu abgeschlossenen' +
    ' Verträgen oder Ähnlichem. <strong>Ihre Daten sind also geschützt.' +
    '</strong></p>' +
    '<p class="a-text a-text-paragraphalt">' +
    '<h3 class="a-headline a-headline-section-black">' +
    'Erklärung gegenüber der plusForta&nbsp;GmbH</h3>' +
    '<p class="a-text a-text-paragraphalt">' +
    '<strong>Einwilligung zur Bonitätsprüfung:</strong></p>' +
    '<p class="a-text a-text-paragraphalt">' +
    'Ich willige ein, dass die plusForta GmbH <strong>zum Zwecke des' +
    ' Vertragsabschlusses</strong> und bei Bedarf im Verlauf der aktiven' +
    ' Geschäfts­beziehung z.B. im Schadenfall zu Zwecken der' +
    ' Vertragsverwaltung und ‑abwicklung Informationen zu meinem' +
    ' Zahlungsverhalten und Bonitätsinformationen von der infoscore' +
    ' Consumer Data GmbH, Rheinstraße 99, 76532 Baden-Baden bezieht und' +
    ' nutzt.</p>' +
    '<p class="a-text a-text-paragraphalt">' +
    '<strong>Die Einwilligung kann jederzeit mit Wirkung für die Zukunft' +
    ' widerrufen werden.</strong> Außerdem besteht ein Auskunftsrecht bei' +
    ' dem Versicherer zu den über mich gespeicherten Daten, deren' +
    ' Herkunft, Empfänger und Zweck der Speicherung.</p>',
  second_tenant_info: 'Sie können einen zweiten Mieter hinzufügen. ' +
    'Manche Vermieter wünschen dies. ' +
    'Sie müssen aber keinen zweiten Mieter angeben, ' +
    'selbst wenn Sie mit mehreren Bewohnern zusammenziehen.',
  non_editable_lessee_after_reservation: 'Ihre persönlichen Daten haben Sie bereits angegeben. ' +
    'Wenn Sie diese jetzt noch einmal ändern möchten, bitten wir Sie, den Antragsprozess noch ' +
    'einmal zu beginnen und die Daten erneut einzugeben.',
  reservation_sub_button_text: '',
  address_autocomplete_placeholder: 'Straße Hausnummer, Stadt',
  selling: {
    sellingSumBox: 'Zusatzleistungen',
    iconTitle: 'Alle Details',
    advantages: {
      mailForwarding: '<ul class="m-list m-list-advantages">' +
        '<li style="display: block;" class="glyphicons glyphicons-ok a-listitem a-listitem-advantage">' +
        'sichere Weiterleitung der Post an Ihre neue Adresse</li>' +
        '<li style="display: block;" class="glyphicons glyphicons-ok a-listitem a-listitem-advantage">' +
        'inkl. Umzugsmitteilung an Ihre geschäftlichen Kontakte (z.B. Versicherungen, Banken).</li>' +
        '<li style="display: block;" class="glyphicons glyphicons-ok a-listitem a-listitem-advantage">' +
        'einmalig 19,90 € für 6 Monate (kein Abo)</li>' +
        '</ul>',
      keySafeing: '<ul class="m-list m-list-advantages">' +
        '<li style="display: block;" class="glyphicons glyphicons-ok a-listitem a-listitem-advantage">Key Safeing Advantage One</li>' +
        '<li style="display: block;" class="glyphicons glyphicons-ok a-listitem a-listitem-advantage">Key Safeing Advantage Two</li>' +
        '<li style="display: block;" class="glyphicons glyphicons-ok a-listitem a-listitem-advantage">Key Safeing Advantage Three</li>' +
        '</ul>',
      keyFinder: '<ul class="m-list m-list-advantages">' +
        '<li style="display: block;" class="glyphicons glyphicons-ok a-listitem a-listitem-advantage">keyFinder Advantage One</li>' +
        '<li style="display: block;" class="glyphicons glyphicons-ok a-listitem a-listitem-advantage">keyFinder Advantage Two</li>' +
        '<li style="display: block;" class="glyphicons glyphicons-ok a-listitem a-listitem-advantage">keyFinder Advantage Three</li>' +
        '</ul>',
      immoscout: '<ul class="m-list m-list-advantages">' +
        '<li style="display: block;" class="glyphicons glyphicons-ok a-listitem a-listitem-advantage">' +
        'bis zu 70 % sparen durch Angebotsvergleich</li>' +
        '<li style="display: block;" class="glyphicons glyphicons-ok a-listitem a-listitem-advantage">' +
        'ausschließlich qualitätsgeprüfte Umzugsunternehmen</li>' +
        '<li style="display: block;" class="glyphicons glyphicons-ok a-listitem a-listitem-advantage">' +
        'komplett unverbindlich</li>' +
        '</ul>',
      movePackage: '<ul class="m-list m-list-advantages">' +
        '<li style="display: block;" class="glyphicons glyphicons-ok a-listitem a-listitem-advantage">' +
        'Umzugshelfer nur 12,50 Euro pro Stunde</li>' +
        '<li style="display: block;" class="glyphicons glyphicons-ok a-listitem a-listitem-advantage">' +
        'Transporter ab 19,00 Euro am Tag</li>' +
        '<li style="display: block;" class="glyphicons glyphicons-ok a-listitem a-listitem-advantage">' +
        'Umzugsbox ab 1,75 Euro</li>' +
        '</ul>',
      wechselpilot: '<ul class="m-list m-list-advantages">' +
        '<li style="display: block;" class="glyphicons glyphicons-ok a-listitem a-listitem-advantage">' +
        'Unverbindliches Angebot von unserem Partner unterbreiten lassen</li>' +
        '<li style="display: block;" class="glyphicons glyphicons-ok a-listitem a-listitem-advantage">' +
        'Wechsel in Auftrag geben</li>' +
        '<li style="display: block;" class="glyphicons glyphicons-ok a-listitem a-listitem-advantage">' +
        'Zurücklehnen und jedes Jahr bis zu 500€ Energiekosten einsparen lassen</li>' +
        '</ul>',
      MieterEngel: '<ul class="m-list m-list-advantages">' +
        '<li style="display: block;" class="glyphicons glyphicons-ok a-listitem a-listitem-advantage">' +
        'Prüfung Ihrer Renovierungspflicht bei Auszug</li>' +
        '<li style="display: block;" class="glyphicons glyphicons-ok a-listitem a-listitem-advantage">' +
        'Hilfe bei der Kautionsrückforderung</li>' +
        '<li style="display: block;" class="glyphicons glyphicons-ok a-listitem a-listitem-advantage">' +
        'Prüfung der Nebenkostenabrechnung auf Fehler</li>' +
        '<li style="display: block;" class="glyphicons glyphicons-ok a-listitem a-listitem-advantage">' +
        'Prüfung des neuen Mietvertrages bei Umzug</li>' +
        '</ul>',
    },
    legalText: {
      immoscout: 'Mit dem Abschluss der Mietkautionsbürgschaft und dem damit ' +
        'einhergehenden Absenden Ihrer Daten erklären Sie sich ' +
        'einverstanden, dass Ihre Daten an ImmobilienScout24 sowie an ' +
        'Umzugsunternehmen zur Angebotserstellung weitergegeben werden. Sie ' +
        'werden telefonisch und/oder per Mail mit einem individuellen ' +
        'Angebot kontaktiert. Der Verwendung Ihrer Daten zum o.g. Zweck ' +
        'können Sie jederzeit ohne Angabe von Gründen gegenüber ' +
        'umzug@immobilienscout24.de widersprechen. Verschiedene ' +
        'Umzugsunternehmen werden Sie in den nächsten Tagen kontaktieren.',
      mailForwarding: 'Mit dem Abschluss der Mietkautionsbürgschaft' +
        ' und dem damit einhergehenden Absenden Ihrer Daten' +
        ' schließen Sie einen Nachsendeauftrag bei der Deutschen' +
        ' Post ab. Sie sind damit einverstanden, dass Sie die' +
        ' Deutsche Post AG wenigstens 2 Bankarbeitstage vor' +
        ' Fälligkeit (Einzug) über den genauen Lastschriftbetrag' +
        ' und das Fälligkeitsdatum informiert. Insoweit verzichte' +
        ' ich / verzichten wir auf die grundsätzliche vorgesehene' +
        ' Ankündigungsfrist von 14 Kalendertagen. Alle Preise' +
        ' verstehen sich inklusive der gesetzlichen Mehrwertsteuer.',
      preisboerse: 'Mit dem Abschluss der Mietkautionsbürgschaft und dem ' +
        'damit einhergehenden Absenden Ihrer Daten erklären Sie sich ' +
        'einverstanden, dass Ihre Daten an Preisbörse Fulda KG zur ' +
        'Angebotserstellung weitergegeben werden. Sie werden telefonisch ' +
        'und/oder per Mail mit einem individuellen Angebot kontaktiert. Der ' +
        'Verwendung Ihrer Daten zum o.g. Zweck können Sie jederzeit ' +
        'ohne Angabe von Gründen gegenüber info@preisboerse24.de ' +
        'widersprechen. Ein Mitarbeiter von preisboerse24.de wird Sie in den ' +
        'nächsten Tagen kontaktieren.',
      wechselpilot: 'Mit dem Abschluss der Mietkautionsbürgschaft und dem damit ' +
        'einhergehenden Absenden Ihrer Daten erklären Sie sich einverstanden, ' +
        'dass Ihre Daten an Wechselpilot zur Angebotserstellung weitergegeben werden. ' +
        'Sie werden telefonisch und/oder per Mail mit einem individuellen Angebot ' +
        'kontaktiert. Der Verwendung Ihrer Daten zum o.g. Zweck können Sie jederzeit ' +
        'ohne Angabe von Gründen gegenüber widerruf@wechselpilot.com widersprechen. ' +
        'Der Kundenservice von Wechselpilot wird Sie in den nächsten Tagen kontaktieren.',
      MieterEngel: 'Mit dem Abschluss der Mietkautionsbürgschaft und dem damit einhergehenden ' +
        'Absenden Ihrer Daten erklären Sie sich einverstanden, dass diese bei oben ' +
        'angeklicktem Einverständnis an Rechtsanwalt Oliver Schmidt weitergegeben werden. Der ' +
        'Verwendung Ihrer Daten zum o. g. Zweck können Sie jederzeit ohne Angabe von ' +
        'Gründen gegenüber info@kautionsfrei.de widersprechen.',
    },
    legalDocument: {
      mailForwarding: {
        'Allgemeine Geschäftsbedingungen für den Nachsendeauftrag':
          'https://shop.deutschepost.de/shop/agb/AGB_Deutsche_Post_Shop.pdf',
      },
      keySafeing: {},
      keyFinder: {},
      selfStorage: {},
      immoscout: {},
      movePackage: {},
      preisboerse: {},
      wechselpilot: {},
    },
    consentText: {
      mailForwarding: 'Wenn Sie den Nachsendeauftrag ausgewählt haben,' +
        ' stimmen Sie gleichzeitig den Allgemeinen' +
        ' Geschäftsbedingungen zum Nachsendeauftrag zu.',
      keySafeing: '',
      keyFinder: '',
      selfStorage: '',
      immoscout: '',
      movePackage: '',
      preisboerse: '',
      wechselpilot: '',
    },
    help: {
      keySafeing: '<h3 class=\"a-headline a-headline-section-black\">24 h-Schlüsselnotdienst</h3>' +
        'Schon gewusst? Ab sofort erhalten Sie zusätzlich zur Mietbürgschaft für ein Jahr einen GRATIS Schlüsselnotdienst' +
        ' – natürlich nur, wenn Sie möchten. Der Service steht Ihnen für ein Jahr zur Verfügung und verlängert sich nicht automatisch.' +
        ' Wählen Sie dafür durch Anklicken der Checkbox einfach das Angebot aus, bevor Sie im Antrag weiter fortfahren.' +
        '<br><br><strong>Ihre Vorteile im Überblick:</strong>' +
        '<div class="m-benefits m-benefits-checkout-sidebar">' +
        '<ul class="m-list m-list-advantages">' +
        '<li style="display: block;" class="glyphicons glyphicons-ok a-listitem a-listitem-advantage">24-Stunden-Notfall-Ansprechpartner bei Schlüsselverlust oder Aussperren</li>' +
        '<li style="display: block;" class="glyphicons glyphicons-ok a-listitem a-listitem-advantage">kostenloses Öffnen der Wohnungstür</li>' +
        '<li style="display: block;" class="glyphicons glyphicons-ok a-listitem a-listitem-advantage">100 % sicher dank ausgewählter Fachfirmen</li>' +
        '<li style="display: block;" class="glyphicons glyphicons-ok a-listitem a-listitem-advantage">Kostenübernahme für ein provisorisches, neues Schloss.</li>' +
        '</ul>' +
        '</div>',
      keyFinder: '<div><h3 class="a-headline a-headline-section-black">Schlüsselfund-Service</h3>' +
        'Die Wahrscheinlichkeit, einen verlorenen Schlüsselbund wiederbekommen, ist normalerweise sehr gering. ' +
        'Aber nicht für kautionsfrei.de Kunden!<br>\n' +
        'Mit dem Welcome-Package, welches wir Ihnen nach Abschluss der Kautionsbürgschaft zusenden, erhalten Sie ' +
        'einen Schlüsselanhänger mit einer registrierten Nummer. Unter diesem Code sind Ihre persönlichen Adressdaten ' +
        'sicher bei uns hinterlegt. Der Finder wird anhand des Textes auf dem Schlüsselanhänger gebeten, den ' +
        'Schlüsselbund einfach in den nächsten Briefkasten zu werfen. Von dort wird er von der Deutschen Post ' +
        'automatisch zum kautionsfrei.de-Service-Center weitergeleitet – und wieder zu Ihnen zurück. Natürlich haben ' +
        'weder der Finder noch die Deutsche Post Zugriff auf Ihre Adressdaten. Um den Schlüsselanhänger zu erhalten, ' +
        'wählen Sie einfach durch Anklicken der Checkbox das Angebot aus, bevor Sie im Antrag weiter fortfahren.' +
        '<br><br><strong>So geht\'s:</strong>' +
        '<div class="m-benefits m-benefits-checkout-sidebar">' +
        '<ul class="m-list m-list-advantages">' +
        '<li style="display: block;" class="glyphicons glyphicons-ok a-listitem a-listitem-advantage">Einfach den Anhänger an Ihrem Schlüsselbund befestigen</li>' +
        '<li style="display: block;" class="glyphicons glyphicons-ok a-listitem a-listitem-advantage">Sie erhalten Ihre Schlüssel kostenlos per Post zurück, wenn sie gefunden werden</li>' +
        '</ul>' +
        '</div>' +
        '</div>',
      selfStorage: '<h3 class=\"a-headline a-headline-section-black\">1 Monat gratis Lagerbox</h3>' +
        '<p>Wenn Du die Option für die gratis Einlagerung auswählst, senden wir Dir nach Abschluss' +
        ' der Mietkautionsbürgschaft eine E-Mail mit einem Gutschein zu. Mit diesem Gutschein' +
        ' erhältst Du bei Lagerbox für einen Monat einen gratis Lagerraum für Deine Möbel und' +
        ' Kisten. Für Deinen Umzug bekommst Du für einen Tag einen gratis Transporter dazu. Die' +
        ' Verleihbedingungen erfährst Du direkt bei Lagerbox.</p>',
      wechselpilot: '<h3 class=\"a-headline a-headline-section-black\"> Automatischer Wechselservice</h3>' +
        '<p>Wechselpilot verfolgt einen gänzlich anderen Ansatz als die herkömmlichen Vergleichsportale.<br> ' +
        'Nach der erstmaligen Beauftragung des Wechselservice <strong>optimiert unser Partner jedes Jahr automatisch</strong> ' +
        'Ihren Energievertrag und wechselt ganz nach Ihren persönlichen Präferenzen in den besten Tarif. <br>Dabei ' +
        'übernimmt Wechselpilot alle notwendigen Schritte im Wechselprozess (Kündigung des Altvertrages, ' +
        'Anmeldung beim neuen Versorger, kontinuierlich Überprüfung des Marktes nach besseren Angeboten) ' +
        'und bildet die komplette Kommunikation mit dem Versorger ab. <br>Da Wechselpilot im Gegensatz zu den ' +
        'Vergleichsportalen keine Provision von den Anbietern erhält, bleibt unser Partner stets unabhängig ' +
        'und empfiehlt ausschließlich seriöse Anbieter weiter. Kosten fallen immer nur im Erfolgsfall an - ' +
        'wenn Geld eingespart wurde, erhält Wechselpilot 20% der Ersparnis als Servicegebühr. Somit besteht für Sie kein Risiko.</p>' +
        '<p><br><br><strong>So geht\'s:</strong></p>' +
        '<div class="m-benefits m-benefits-checkout-sidebar">' +
          '<ul class="m-list m-list-advantages">' +
            '<li style="display: block;" class="glyphicons glyphicons-ok a-listitem a-listitem-advantage">Unverbindliches Angebot von unserem Partner unterbreiten lassen</li>' +
            '<li style="display: block;" class="glyphicons glyphicons-ok a-listitem a-listitem-advantage">Wechsel in Auftrag geben</li>' +
            '<li style="display: block;" class="glyphicons glyphicons-ok a-listitem a-listitem-advantage">Zurücklehnen und jedes Jahr bis zu 500€ Energiekosten einsparen lassen</li>' +
          '</ul>' +
        '</div>',
    },
    overviewLabels: {
      mailForwarding: 'Nachsendeauftrag inkl. MwSt. einmalig',
      keySafeing: '24 h-Schlüsselnotdienst',
      keyFinder: 'Schlüsselfund-Service',
      immoscout: 'Individuelle Umzugsangebote',
      selfStorage: '1 Monat Möbel lagern',
      movePackage: 'Link zum Umzugspaket',
      preisboerse: 'Beratung Internet- und Telefonanschluss',
      wechselpilot: 'Versorger-Wechselservice',
      MieterEngel: 'Umzugscheck durch kautionsfrei.de Anwalt',
      MieterEngel_phone: 'Kontaktaufnahme per E-Mail und/oder Telefon von kautionsfrei',
    },
    overviewValueYearly: {
      mailForwarding: '19,90 €',
      immoscout: 'Gratis',
      keySafeing: 'Gratis',
      keyFinder: 'Gratis',
      selfStorage: 'Gratis',
      movePackage: 'Gratis',
      preisboerse: 'Gratis',
      wechselpilot: 'Gratis Angebot erhalten',
      MieterEngel: 'Gratis',
      MieterEngel_phone: ' ',
    },
    introName: {
      mailForwarding: 'Nachsendeauftrag',
      keySafeing: '24 h-Schlüsselnotdienst',
      keyFinder: 'Schlüsselfund-Service',
      immoscout: 'Individuelle Umzugsangebote',
      selfStorage: '1 Monat gratis Möbel lagern',
      movePackage: 'Selber umziehen',
      preisboerse: 'Frühzeitig an den Internet- und Telefonanschluss denken',
      wechselpilot: 'Versorger-Wechselservice',
      MieterEngel: 'Gutschein: kostenlose Erstberatung durch kautionsfrei.de Anwalt',
      // blank line to sync with english version
    },
    introExplain: {
      mailForwarding: 'Buchen Sie den Nachsendeauftrag von der' +
        ' Deutschen Post gleich mit hinzu. 6 Monate lang wird' +
        ' Ihnen Ihre Post nachgeschickt.',
      selfStorage: 'Mit unserem Kooperationspartner Lagerbox kannst Du' +
        ' jetzt einen Monat lang so viele Umzugskisten und Möbel' +
        ' einlagern, wie Du möchtest. Einen Transporter für einen' +
        ' Tag gibt es kostenlos dazu.',
      keySafeing: 'Für ein Jahr hilft Ihnen unser Schlüsselnotdienst, wenn ' +
        'Sie Ihren Schlüssel verloren oder abgebrochen haben. Der Service ' +
        'ist kostenlos und endet automatisch nach einem Jahr.',
      keyFinder: 'Der kautionsfrei.de Schlüsselanhänger hilft Ihnen verlorene Schlüssel wiederzubekommen und spart ' +
        'Ihnen damit Zeit, Geld und vor allem Sicherheitsrisiken.',
      immoscout: 'Jetzt ganz einfach und stressfrei das perfekte ' +
        'Umzugsunternehmen finden: Erhalten Sie maßgeschneiderte Angebote ' +
        'für Ihren Umzug von unserem Kooperationspartner ImmobilienScout24.',
      movePackage: 'Stellen Sie in wenigen Schritten Ihr individuelles' +
        'Umzugspaket zusammen. Sie zahlen nur, was Sie wirklich brauchen.',
      preisboerse: 'Egal bei welchem Anbieter Sie Kunde sind – wir machen ' +
        'Ihnen ein attraktives Angebot für einen neuen ' +
        'Internet/Telefonanschluss. Nutzen Sie jetzt den kostenlosen ' +
        'Rückrufservice und lassen Sie sich unverbindlich beraten',
      wechselpilot: 'Mit unserem Partner Wechselpilot jährlich im optimalen ' +
        'Strom- & Gastarif landen. Sie lehnen sich zurück und sparen – ' +
        'der Wechselservice kümmert sich um den Rest.',
      MieterEngel: 'Jetzt auswählen und rechtliche Sicherheit in allen Mietsituationen haben:',
      // blank line to sync with english version
    },
    iconName: {
      mailForwarding: 'envelope',
      keySafeing: 'keys',
      keyFinder: 'keys',
      immoscout: 'truck',
      selfStorage: 'keys',
      movePackage: 'cargo',
      preisboerse: 'phone-alt',
      wechselpilot: 'flash',
      MieterEngel: 'scale-classic',
    },
    optionLabels: {
      keySafeing: {
        'yes': 'Ja, ich möchte mich kostenfrei mit dem Schlüsselnotdienst ' +
          'absichern.',
        'no': 'Nein danke, ich möchte mich nicht absichern.',
      },
      keyFinder: 'Ja, ich möchte den Schlüsselfund-Service',
      mailForwarding: 'Ja, ich will den Nachsendeauftrag kostenpflichtig ' +
        'dazu buchen.',
      immoscout: 'Ja, ich will kostenlose Umzugsangebote erhalten.',
      movePackage: 'Ja, ich möchte unverbindlich die Kosten für mein' +
        ' Umzugspaket berechnen. Bitte schicken Sie mir den Link zum Rechner.',
      preisboerse: 'Ja, ich möchte telefonisch beraten werden und 240€ ' +
        'Prämie sichern',
      wechselpilot: 'Ja, ich will Strom- & Gaskosten sparen, ohne mich darum kümmern zu müssen.',
      MieterEngel: 'Ja, ich möchte den Gutschein erhalten.',
      MieterEngel_phone: 'Ja, ich bin damit einverstanden, dass mich kautiosnfrei telefonisch und/oder per Mail kontaktiert.',
    },
    acceptanceExplain: 'Ich habe alle Dokumente zu den Produkten: ' +
      ' %PRODUCTS% erhalten und gelesen.',
    extensionFields: {
      immoscout_area: 'Fläche der aktuellen Wohnung: (Fläche in m²)',
      preisboerse_time: 'Wunsch Rückruf Uhrzeit: (zwischen 8:00 und 17:00)',
    },
    helpExtensionFields: {
      immoscout_area: '<h3 class="a-headline a-headline-section-black"' +
        '>Fläche der aktuellen Wohnung</h3>Bitte geben Sie hier die' +
        ' Fläche Ihrer aktuellen Wohnung in Quadratmeter an. Diese' +
        ' wird benötigt, um dem Umfang des Umzugs einschätzen zu' +
        ' können.',
      preisboerse_time: 'Für die Beratung rufen wir Sie innerhalb von 48 Stunden ' +
        'kostenlos zurück. Bitte wählen Sie ein Zeitfenster von 3 Stunden, ' +
        'in dem wir Sie anrufen dürfen.',
    },
  },
};

/**
 * load proper help content when click on a help button occures
 * @param  {String} id
 *         id-string of the help issue which is requested
 * @return {String/Promise}
 *         return a String with html or a promise for asyncronous loading.
 *         The promise should resolve to a string with html.
 */
function i18nInterface(id) {
  if (get(i18nData, id, null) != null) {
    return get(i18nData, id);
  }

  switch (id) {
    /** ----------- start page ----------- **/
    case 'objective.deposit':
      return new Promise((resolve) => {
        const dataProcessor = (rates) => {
          let rateInfoHtml = '<ul>';
          for (const rateKey in rates) {
            rateInfoHtml += `<li>bei Zahlweise ${rates[rateKey].i18n_adverb}: ${(rates[rateKey].rate_per_year_factor * 100).toFixed(2).replace('.', ',')} % der Bürgschaftssumme</li>`;
          }
          rateInfoHtml += '</ul>';

          resolve('<h3 class="a-headline a-headline-section-black">' +
            'Beitragshöhe</h3>Ihr Beitrag für Ihre Mietbürgschaft richtet ' +
            'sich nach Höhe der Bürgschaftssumme und der Zahlweise.' +
            rateInfoHtml +
            'Die Auswahl zur monatlichen oder jährlichen Zahlweise treffen Sie in Schritt 3 (Zahlung). <br>' +
            'Die erste Abbuchung erfolgt ca. 14 Tage nach Antragstellung.<br><br>' +
            'Bitte geben Sie die Höhe der Mietkaution gemäß Mietvertrag an. ' +
            'Laut Gesetz darf die Mietkaution nicht höher als das 3-fache der Kaltmiete sein.<br> ' +
            'Wir bieten Bürgschaften von 400 EUR bis maximal 15.000 EUR an.' +
            '<h3>Gewerbemieter</h3>Auch für gewerbliche Mietverhältnisse bieten ' +
            'wir eine Mietkautionsbürgschaft an. <a href="/kautionsbuergschaft/gewerbe/abschliessen">' +
            'Hier geht es zum Gewerbeantrag</a></div>',
          );
        };
        getPaymentConditions(dataProcessor, 0);
      });
    case 'objective.target':
      return '<h3 class="a-headline a-headline-section-black">Wozu benötigen ' +
        'Sie die Kautions&shy;bürgschaft?</h3>Bitte geben Sie hier an, ob es ' +
        'sich um eine Mietkaution für ein neues Mietverhältnis handelt oder ' +
        'ob Sie eine schon hinterlegte Mietkaution beim Vermieter ablösen ' +
        'möchten.';
    /** ----------- object page ----------- **/
    case 'object.address':
      return '';
    case 'object.coldRent':
      return '<h3 class="a-headline a-headline-section-black">Kaltmiete</h3>' +
        'Die Kaltmiete ist die zu zahlende Miete ohne anfallende Nebenkosten. ' +
        'Sie finden diese in Ihrem Mietvertrag.';
    case 'object.moveInDate':
      return '<h3 class="a-headline a-headline-section-black">Einzugsdatum' +
        '</h3>Bitte übernehmen Sie das Einzugsdatum  (Datum des Mietbeginns) ' +
        'aus dem Mietvertrag.  Dies wird zur Erstellung der ' +
        'Bürgschaftsurkunde benötigt.';
    case 'object.contractSignDate':
      return '<h3 class="a-headline a-headline-section-black">' +
        'Mietbeginn</h3>Bitte geben Sie hier das im Mietvertrag angegebene Datum des Mietbeginns an.';
    case 'object.isContractLimited':
      return '';
    case 'object.isContractTerminated':
      return '<h3 class="a-headline a-headline-section-black"></h3>' +
        'Kautionsbürgschaften können nur für laufende Mietverträge ' +
        'abgeschlossen werden. Ist ihr Mietvertrag bereits gekündigt, ist ' +
        'eine Beantragung ausgeschlossen.';

    /** ----------- frist tenant page ----------- **/
    case 'firstTenant.address':
      return '<h3 class="a-headline a-headline-section-black">Meldeadresse</h3>' +
        'Ihre aktuelle Meldeadresse finden Sie in der Anmeldebestätigung Ihres zuständigen Bürgeramtes oder auf der ' +
        'Rückseite Ihres Personalausweises.<br/><br/>' +
        'Um Ihnen das Ausfüllen der Adressfelder zu erleichtern, werden Ihnen schon bei der Eingabe Ihrer Straße ' +
        'Vorschläge unterhalb der Suchbox angezeigt. Wählen Sie Ihre Adresse aus der Ergebnisliste aus, die anderen ' +
        'Adressfelder vervollständigen sich nun automatisch. Prüfen und ergänzen Sie gegebenenfalls die Daten im ' +
        'Formular.<br/><br/>' +
        'Natürlich können Sie Ihre Daten auch gern manuell in die Felder darunter eingeben. Sie haben weitere Fragen ' +
        'zur Vervollständigung des Adressformulars? Melden Sie sich gern bei unserem Kundenservice unter der ' +
        'Rufnummer 0800 - 01 22 333';
    /** ----------- second tenant page ----------- **/
    case 'secondTenant.address':
      return '<h3 class="a-headline a-headline-section-black">Meldeadresse</h3>' +
        'Ihre aktuelle Meldeadresse finden Sie in der Anmeldebestätigung Ihres zuständigen Bürgeramtes oder auf der ' +
        'Rückseite Ihres Personalausweises.<br/><br/>' +
        'Um Ihnen das Ausfüllen der Adressfelder zu erleichtern, werden Ihnen schon bei der Eingabe Ihrer Straße ' +
        'Vorschläge unterhalb der Suchbox angezeigt. Wählen Sie Ihre Adresse aus der Ergebnisliste aus, die anderen ' +
        'Adressfelder vervollständigen sich nun automatisch. Prüfen und ergänzen Sie gegebenenfalls die Daten im ' +
        'Formular.<br/><br/>' +
        'Natürlich können Sie Ihre Daten auch gern manuell in die Felder darunter eingeben. Sie haben weitere Fragen ' +
        'zur Vervollständigung des Adressformulars? Melden Sie sich gern bei unserem Kundenservice unter der ' +
        'Rufnummer 0800 - 01 22 333';
    case 'secondTenant.isCreditCheckApproved':
      return '<h3 class="a-headline a-headline-section-black">' +
        'Bonitätsprüfung</h3>Um eine Bürgschaft ausstellen zu können, sind ' +
        'wir verpflichtet, eine Bonitätsprüfung durchzuführen. Dafür ' +
        'benötigen wir hier Ihre Zustimmung.';
    /** ----------- landlord ----------- **/
    case 'landlord.personalData':
      return '<h3 class="a-headline a-headline-section-black">Vermieter</h3>' +
        'Bitte übernehmen Sie die vollständigen Daten Ihres Vermieters aus ' +
        'dem Mietvertrag. Vertretungen (z.B. Hausverwaltungen) müssen nicht ' +
        'übernommen werden. Liegt die Adresse Ihrer Vermietung im Ausland, ' +
        'kontaktieren Sie uns bitte unter der kostenlosen Hotline 0800 – 01 ' +
        '22 333';
    case 'landlord.address':

    /** ----------- payment ----------- **/
    case 'bankAccount.iban':
      return '<h3 class="a-headline a-headline-section-black">IBAN</h3>' +
        'Die IBAN (International Bank Account Number) dient zur ' +
        'Identifizierung von Bankverbindungen. Sie finden die IBAN auf Ihrer ' +
        'girocard. Sollte diese dort nicht aufgeführt sein, können Sie die ' +
        'IBAN online unter <a href="https://de.iban.com/iban-rechner" ' +
        'target="_blank"> https://de.iban.com/iban-rechner </a> errechnen' +
        ' lassen oder bei Ihrer Bank erfragen.';
    case 'objective.deliverTo':
      return '';
    case 'objective.deliverType':
      return '';
    case 'bankAccount.captcha':
      return '<h3 class="a-headline a-headline-section-black">reCAPTCHA</h3>' +
        'Bitte lösen Sie die Sicherheitsaufgabe(n).' +
        'Mit dieser Abfrage stellen wir sicher, dass Sie ein legitimer ' +
        'Nutzer und keine Angriffssoftware sind.';
    case 'objective.paymentConditions':
      return '';
    case 'billing.partner_code':
      return '<h3 class="a-headline a-headline-section-black"' +
        '>Partnerschaftsprogramm</h3>' +
        'Die Angabe der PartnerID ist nur in sehr wenigen Fällen notwendig.' +
        ' Wir arbeiten mit verschiedenen Kooperationspartnern zusammen ' +
        '(Hausverwaltungen, Banken, Maklern, ... ). Mithilfe der PartnerID ' +
        'können wir vermittelten Verträgen die jeweiligen Partner zuordnen.' +
        ' Wenn Sie nicht von einem unserer Partner explizit darauf ' +
        'hingewiesen wurden eine PartnerID anzugeben, lassen Sie dieses ' +
        'Feld einfach frei.';
    default:
      // example syncronous add help info with promises
      return `<h1>Missing help for id ${id}</h1>`;
  }
}

export {i18nInterface, loadHtmlContent};
