/**
* @Author: Michael Neumair <mBook>
* @Date:   2016-09-27T12:16:48+02:00
* @Email:  7q7w7e7r@gmail.com
* @Last modified by:   mBook
* @Last modified time: 2016-10-04T16:11:50+02:00
*/

import uniq from 'lodash/uniq';
import concat from 'lodash/concat';
import clone from 'lodash/clone';
import {combineForms} from 'react-redux-form/src';
import checkoutFormReducer from './checkoutFormReducer';
import checkoutHelpReducer from './checkoutHelpReducer';
import {HAS_ACTIVE_MASK_FOR} from 'checkout/actions/setActiveMaskActions';

export const FORM_MODEL = 'checkout'; // based model of the checkout form

export default {
  // see: https://davidkpiano.github.io/react-redux-form/docs/api/combineForms.html
  [FORM_MODEL]: (state, action) => {
    const newState = combineForms(checkoutFormReducer, FORM_MODEL)(state, action);

    if ( action.type == HAS_ACTIVE_MASK_FOR ) {
      if (action.activeMaskMerge) {
        newState.activeMask = uniq(
            concat(
                state.activeMask,
                action.activeMask
            )
        );
      } else {
        newState.activeMask = clone(action.activeMask);
      }
    }

    return newState;
  },
  checkoutHelp: checkoutHelpReducer,
};

