/**
* @Author: Michael Neumair <mBook>
* @Date:   2016-09-29T18:06:40+02:00
* @Email:  7q7w7e7r@gmail.com
* @Last modified by:   mBook
* @Last modified time: 2016-09-30T22:29:50+02:00
*/
import genderValues from './genderValues';

export const MALE = genderValues.male;
export const FEMALE = genderValues.female;
export const COMPANY = 'company';

export default {
  male: MALE,
  female: FEMALE,
  company: COMPANY,
};
