/**
* @Author: Michael Neumair <mBook>
* @Date:   2016-04-25T17:03:09+02:00
* @Email:  7q7w7e7r@gmail.com
* @Last modified by:   mBook
* @Last modified time: 2016-10-07T17:09:19+02:00
*/


import React, {Component} from 'react';
import PropTypes from 'prop-types';
import buttonProps from './../../../utilities/buttonProps';

/**
 * AButton - …
**/
class AButton extends Component {
  /**
     * react render() <AButton>
     *
     * @return {object}
     */
  render() {
    const {
      look,
      size,
      color,
      title,
      icon,
      iconPosition,
      label,
      disabled,
      onPress,
      visualState,
      children = label,
    } = this.props;
    // define look
    let lookClass;
    switch (look) {
      case 'filled':
        lookClass = 'a-button-fill'; break;
      case 'outline':
      default:
        lookClass = 'a-button-outline'; break;
    }

    // define size
    let sizeClass;
    switch (size) {
      case 'big':
        sizeClass = 'big'; break;
      case 'small':
        sizeClass = 'small'; break;
      case 'normal':
      default:
        sizeClass = ''; break;
    }

    // define icon
    const iconButton = (icon) ? (<span className={`a-icon glyphicons glyphicons-${icon}`}></span>) : null;

    return (
      <button
        className={`a-button ${lookClass} ${sizeClass} ${color} ${visualState}`} // TODO: add size props
        title={title}
        onClick={onPress}
      >
        {(iconPosition === 'left') && iconButton}
        {children}
        {(iconPosition === 'right') && iconButton}
      </button>
    );
  }
}


AButton.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  iconPosition: PropTypes.oneOf(['left', 'right']),
  size: PropTypes.oneOf(['big', 'normal', 'small']),
  look: PropTypes.oneOf(['outline', 'filled']),
  color: PropTypes.oneOf(['green', 'lila', 'orange', 'black']),
  label: PropTypes.string,
  children: PropTypes.any,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  visualState: PropTypes.string, // passed by buttonProps
  onPress: PropTypes.func,
};
AButton.defaultProps = {
  size: 'normal',
  look: 'outline',
  color: 'lila',
  label: 'AButton',
  iconPosition: 'left',
};
export default buttonProps(AButton);

