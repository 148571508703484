import {
  getCommUrl,
  LOAD_FROM_CID_URL,
  SAVE_PROGRESS_URL,
  SAVE_TO_CID_URL,
} from './corsTargets';
import {applyMapping, getFlatFormValues} from '../checkout/utils/getFormValues';
import getMapping from '../config/mapping';
import {useNewApi} from '../config/constants.common';
import store from "../checkout/store";


/**
 * xhrLoadCid
 * return Promise that gives javascript object on given cid string to be processed by
 * application logic (in given schema)
 *
 * @param {string} cid
 * @return {Promise<any>}
 */
function xhrLoadCid(cid) {
  const query = {
    ...(cid && cid.length > 1) ?
            {cid: cid} :
            {},
    ...(typeof window != 'undefined' && window.OA_RECONF_TO) ?
            {reconf_to: window.OA_RECONF_TO} :
            {},
  };

  const url = getCommUrl(LOAD_FROM_CID_URL, query);

  return sendRequest(url);
}

/**
 * Returns promise for a xmlHttpRequest
 *
 * @param {string} url
 * @param {string} method
 * @param {string} body
 * @return {Promise<any>}
 */
function sendRequest(url, method, body) {
  method = method || 'GET';
  body = body || null;
  return new Promise((resolve, error) => {
    const oReq = new XMLHttpRequest();
    if (useNewApi()) {
      oReq.withCredentials = true;
    }

    oReq.addEventListener('load', function() {
      resolve(this);
    });
    oReq.addEventListener('error', function() {
      error(this);
    });
    oReq.addEventListener('abort', function() {
      error(this);
    });
    oReq.open(method, url, true);

    if (body || method !== 'GET') {
      oReq.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    }

    oReq.send(body);
  });
}

/**
 * Converts data object to key value url string
 *
 * @param {object} data
 * @return {string}
 */
function getBody(data) {
  let body = '';
  for (const key in data) {
    body += encodeURIComponent(key) + '=' + encodeURIComponent(data[key]) + '&';
  }

  body = body.replace(/&$/, '');
  return body;
}

/**
 * Get method for request.
 *
 * @param {boolean} createResource
 * @return {string}
 */
function getMethod(createResource) {
  if (!useNewApi()) {
    return 'PUT';
  }

  return createResource ? 'POST' : 'PATCH';
}

/**
 * Send form data of current page of onlineantrag to Backend
 *
 * @param {string} cid
 * @param {string} step
 * @param {boolean} isReservationActive
 * @return {Promise}
 */
function xhrSaveCid(cid, step, isReservationActive) {
  const query = {
    ...(cid && cid.length > 1) ?
            {cid: cid} :
            {},
    ...(step && step.length > 1) ?
            {step: step} :
            {},
    ...(typeof window != 'undefined' && window.OA_PRODUCT_SELECTION) ?
            {product_selection: window.OA_PRODUCT_SELECTION} :
            {},
    ...(typeof window != 'undefined' && window.OA_RECONF_TO) ?
            {reconf_to: window.OA_RECONF_TO} :
            {}
  };

  const data = applyMapping(
      getMapping(),
      getFlatFormValues(null, {}, ''),
  );

  const body = getBody(data);
  let url = getCommUrl(SAVE_TO_CID_URL(isReservationActive), query);

  const method = getMethod(typeof cid === 'undefined' || cid === null);
  return sendRequest(url, method, body);
}

function saveProgress(cid, step, email) {
  const body = getBody({
    cid: cid,
    step: step,
    email: email,
  });

  const url = getCommUrl(SAVE_PROGRESS_URL);
  return sendRequest(url, 'POST', body);
}


export {
  xhrSaveCid,
  xhrLoadCid,
  saveProgress,
};
