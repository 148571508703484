/**
* @Author: Michael Neumair <mBook>
* @Date:   2016-09-29T18:06:40+02:00
* @Email:  7q7w7e7r@gmail.com
* @Last modified by:   mBook
* @Last modified time: 2016-10-06T18:29:53+02:00
*/

export const NEW_CONTRACT = 'newContract';
export const RETRIEVE_DEPOSIT = 'retrieveDeposit';

export default {
  newContract: NEW_CONTRACT,
  retrieveDeposit: RETRIEVE_DEPOSIT,
};
