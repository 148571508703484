export const isKfx = () => window && window.OA_RECONF_TO && (/^modern_kfx_[0-9]{4}/).test(window.OA_RECONF_TO);
export const useNewApi = () => window && window.USE_NEW_API;
export const offerDigital = () => window && window.OFFER_DIGITAL || false;

import kfxConstants from './constants_kfx';
import kfConstants from './constants_kf';

const DATE_OF_BIRTH_MIN_DATE = new Date('1900-01-01');
const DATE_OF_BIRTH_MIN_AGE = 18;

const IBAN_ALLOWED_COUNTRIES = ['AT', 'BE', 'BG', 'CH', 'CY', 'CZ', 'DE', 'DK', 'EE', 'ES', 'FI',
  'FR', 'GB', 'GI', 'GR', 'HR', 'HU', 'IE', 'IS', 'IT', 'LI', 'LT', 'LU', 'LV', 'MC', 'MT', 'NL',
  'NO', 'PL', 'PT', 'RO', 'SE', 'SI', 'SK', 'SM'];

export default () => {
  let constants = {};
  if (isKfx()) {
    constants = {
      MOVE_DATE_MAX_DAYS_IN_PAST: kfxConstants.MOVE_DATE_MAX_DAYS_IN_PAST,
      RENTAL_DATE_MAX_DAYS_IN_FUTURE: kfxConstants.RENTAL_DATE_MAX_DAYS_IN_FUTURE,
      DEPOSIT_MAX: kfxConstants.DEPOSIT_MAX,
      DEPOSIT_MIN: kfxConstants.DEPOSIT_MIN,
      DATE_OF_BIRTH_MIN_AGE: DATE_OF_BIRTH_MIN_AGE,
      DATE_OF_BIRTH_MIN_DATE: DATE_OF_BIRTH_MIN_DATE,
      IBAN_ALLOWED_COUNTRIES: IBAN_ALLOWED_COUNTRIES,
    };
  } else {
    constants = {
      MOVE_DATE_MAX_DAYS_IN_PAST: kfConstants.MOVE_DATE_MAX_DAYS_IN_PAST,
      RENTAL_DATE_MAX_DAYS_IN_FUTURE: kfConstants.RENTAL_DATE_MAX_DAYS_IN_FUTURE,
      DEPOSIT_MAX: kfConstants.DEPOSIT_MAX,
      DEPOSIT_MIN: kfConstants.DEPOSIT_MIN,
      DATE_OF_BIRTH_MIN_AGE: DATE_OF_BIRTH_MIN_AGE,
      DATE_OF_BIRTH_MIN_DATE: DATE_OF_BIRTH_MIN_DATE,
      IBAN_ALLOWED_COUNTRIES: IBAN_ALLOWED_COUNTRIES,
    };
  }

  return constants;
};
