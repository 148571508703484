/**
* @Author: Michael Neumair <mBook>
* @Date:   2016-10-04T16:01:35+02:00
* @Email:  7q7w7e7r@gmail.com
* @Last modified by:   mBook
* @Last modified time: 2016-10-05T09:32:32+02:00
*/

export const CLOSE_HELP = 'checkoutHelp/CLOSE_HELP';
export const SET_HELP_HTML = 'checkoutHelp/SET_HELP_HTML';
export const OPEN_HELP = 'checkoutHelp/OPEN_HELP';

/**
 * TODO jsdoc
 *
 * @return {object}
 */
export function closeHelp() {
  return {
    type: CLOSE_HELP,
  };
}

/**
 * TODO jsdoc
 *
 * @param {string} helpId
 * @return {object}
 */
export function openHelp(helpId = null) {
  return {
    type: OPEN_HELP,
    payload: {
      helpId,
    },
  };
}

/**
 * TODO jsdoc
 *
 * @param {string} html
 * @return {object}
 */
export function setHelpHtml(html) {
  return {
    type: SET_HELP_HTML,
    payload: {
      html,
    },
  };
}
